import {useEffect, useState} from "react";

type Props = {
  data: string[],
  className?: string,
}
const TextLoop = ({data, className}: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [data.length]);

  return (
    <span className="text-loop">
      <span className={`${className ?? ''}`}>{data[currentIndex]}</span>
    </span>
  )
}

export default TextLoop

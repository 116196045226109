import {useEffect} from "react";
import {Container} from "react-bootstrap";

import Hero from "./atom/Hero";
import Products from "./atom/Products";
import {AppTheme} from "../../modules/enums";
import {SourceType} from "../../modules/enums";
import Brands from "../../components/fragment/Brands";
import {useTheme} from "../../components/hooks/useTheme";
import Testimony from "../../components/fragment/Testimony";
import PageLayout from "../../components/layouts/PageLayout";
import AppStoreBanner from "../../components/fragment/AppStoreBanner";
import {CompanyInformation, HomeTestimonies} from "../../utils/mapper/Mapper";
import CircleThumbnailWithIcons from "../../components/fragment/CircleThumbnails";

const Home = () => {
  const appTheme = useTheme();

  useEffect(() => {
    if (appTheme.theme === AppTheme.LIGHT) appTheme.toggleTheme(AppTheme.DARK);
  }, [appTheme]);

  return (
    <PageLayout headerTheme={AppTheme.DARK} footerTheme={AppTheme.DARK}>
      <Hero/>
      <Brands/>
      <main className="light-theme py-5 d-flex justify-content-center flex-column">
        <Container>
          <h3 className="text-darker text-center mt-5 mt-md-70 w-720 mx-auto">We are empowering commercial logistics
            fleet operators.</h3>
          <p className="text-medium text-center mt-3 mt-md-2 mb-4">
            Providing everything you need to be efficient to yourselves and the market you service.
          </p>
          <CircleThumbnailWithIcons companyInfo={CompanyInformation} source={SourceType.HOME}/>
          <Products/>
        </Container>
        <Testimony testimonyData={HomeTestimonies}/>
        <AppStoreBanner/>
      </main>
    </PageLayout>
  );
}

export default Home;

import {Fragment} from "react";
import {IPolicyExtra} from "../../../modules/components";
import {PolicyLinkPlacement} from "../../../modules/enums";
import PolicyLinks from "../../../components/navigation/PolicyLinks";

const PoliciesOrderedList = ({policyExtras}: { policyExtras: IPolicyExtra[] }) => (
  <ol className="my-3">
    {policyExtras.map((item, itemIndex) =>
      <li key={itemIndex} className="mb-2">
        <h6>
          {item?.title}
          {(item.link && item.link.placement === PolicyLinkPlacement.TITLE) && <PolicyLinks link={item.link}/>}
        </h6>
        <span>
          <strong>{item?.subTitle}{" "}</strong>{item?.description}{" "}
          {(item.link && item.link.placement === PolicyLinkPlacement.DESCRIPTION) && <PolicyLinks link={item.link}/>}
        </span>
        <span>
              {(item && item.extra) &&
								<ol type="i" className="my-3">
                  {item.extra.map((extra, extraIndex) =>
                    <li key={extraIndex} className="my-1">
                      {extra}{" "}
                      {((item.extra?.length === extraIndex + 1) && item.link && item.link.placement === PolicyLinkPlacement.MORE) &&
												<PolicyLinks link={item.link}/>
                      }
                    </li>
                  )}
								</ol>
              }
            </span>
        <span>
          {(item.footer && typeof item.footer === "string")
            ? <p className="mt-2">
              {item.footer}{" "}
              {(item.link && item.link.placement === PolicyLinkPlacement.FOOTER) && <PolicyLinks link={item.link}/>}
            </p>
            : <Fragment>
              {Array.isArray(item.footer) && item.footer.map((footer, footerIndex) =>
                <p key={footerIndex} className="mt-3">
                  {footer}{" "}{(item.link && item.link.placement === PolicyLinkPlacement.FOOTER) &&
									<PolicyLinks link={item.link}/>
                }
                </p>
              )}
            </Fragment>
          }
        </span>
      </li>
    )}
  </ol>
)

export default PoliciesOrderedList

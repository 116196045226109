import {Image} from "react-bootstrap";

const WeWorkTogether = () => (
  <section className="mt-xxl-180">
    <div className="d-flex flex-column mx-auto w-771 mt-5">
      <h3 className="align-self-center mt-md-130">We Work Together</h3>
      <p className="font-size-18 text-start text-md-center mb-5 mt-2 px-md-5">
        One will chase one thousand, two will chase ten thousand - Now
        imagine the collective power of the entire team- what the whole
        team can achieve working together, the result we can deliver and
        possibilities we can bring to life.
      </p>
    </div>
    <div className="d-flex justify-content-center gap-3 px-md-5 mt-5 mb-5 mb-md-0">
      <div style={{maxWidth: "280px", maxHeight: "380px", marginBottom: "-60px"}}>
        <Image
          alt="WORK_1"
          src="https://storage.googleapis.com/public_storage_truq/website/career_we_work_together_1_vcbxhsde.jpg"
          className="img-fluid object-fit-cover  rounded-2"
        />
      </div>
      <div style={{maxWidth: "280px", maxHeight: "380px", marginTop: "-40px"}}>
        <Image
          alt="WORK_2"
          src="https://storage.googleapis.com/public_storage_truq/website/career_we_work_together_2_reuaished.jpg"
          className="img-fluid object-fit-cover rounded-2"
        />
      </div>
      <div style={{maxWidth: "280px", maxHeight: "380px", marginBottom: "-60px"}}>
        <Image
          alt="WORK_2"
          src="https://storage.googleapis.com/public_storage_truq/website/career_we_work_together_3_abxzsjdioc.jpg"
          className="img-fluid object-fit-cover rounded-2"
        />
      </div>
    </div>
  </section>
)

export default WeWorkTogether;

import { DriveHubSteps } from "../../../utils/mapper/Mapper";
import DriveHubActions from "../../../components/buttons/DriveHubActions";
import { Container, Image} from "react-bootstrap";
import { AsyncImage } from "loadable-image";

type StepProps = {
  src: string;
  alt: string;
  number: string;
  title: string;
  description?: string;
};

const Step = ({ src, alt, number, title, description }: StepProps) => (
  <div className="step">
    <div className="step-img-container"><Image src={src} alt={alt}/></div>

    <div className="ms-2">
      <h6 className="step-number">{number}</h6>
      <p className="fw-bold mb-1 mx-0">{title}</p>
      {description && <p className="text-medium font-size-14">{description}</p>}
    </div>
  </div>
);

const DriveHubGetStarted = ({ handleClick }: { handleClick: () => void }) => (
  <section className="mt-md-130 mb-5 mb-md-70">
    <Container className="text-center">
      <h3 className="section-title mx-auto mb-2">How to get started</h3>
      <DriveHubActions className="mt-3 mb-5" handleClick={handleClick} />
      <div className="d-flex flex-column flex-md-wrap flex-lg-nowrap flex-md-row justify-content-center gap-sm-1 gap-md-3">
        {DriveHubSteps.map((step, index) => (
          <Step key={index} src={step.src} alt={step.alt} number={step.number} title={step.title}
            description={step.description} />
        ))}
      </div>
    </Container>
  </section>
)
export default DriveHubGetStarted;

import {Col, Image, Row} from "react-bootstrap";

const ProjectYou = () => (
  <Row className="mb-2 mb-md-0 mt-xxl-180">
    <Col lg={7} className="mt-md-130 mt-5">
      <Image
        alt="CULTURE_2"
        src="https://storage.googleapis.com/public_storage_truq/website/career_project_you_sfhdgjf.jpeg"
        className="rounded-4"
      />
    </Col>
    <Col lg={5} className="mb-5 my-md-auto ps-md-5 text-start">
      <h4 className="mt-3 mt-md-130 text-center text-md-start">Project You</h4>
      <p className="font-size-18 mt-2 mb-3">
        At truQ, we recognize that your ability to offer value is
        limited by your current capacity. That's why we're dedicated
        to fostering your personal growth. We believe that as you
        continue to grow, your capacity will expand, enabling you to
        achieve even more
      </p>
    </Col>
  </Row>
)
export default ProjectYou

import { useTheme } from "../../components/hooks/useTheme";
import { AppTheme, ErrorNavType } from "../../modules/enums";
import ErrorLayout from "../../components/layouts/ErrorLayout";
import ImageSVG from "../../assets/images/svgs/construction.svg";
import ImageWhiteSVG from "../../assets/images/svgs/construction-white.svg";


const UnderConstruction = () => {
  const appTheme = useTheme();

  return (
    <ErrorLayout title="Page is Under Construction"
      image={appTheme.theme === AppTheme.DARK ? ImageSVG : ImageWhiteSVG}
      imageAlt="CONSTRUCTION"
      navigateType={ErrorNavType.G0_BACK}
      description={`Oops! The page you're looking for cannot be found. It seems like
          the link might be broken or the page has been removed.
          Please double-check the URL or return to the homepage`} />
  );
}

export default UnderConstruction;
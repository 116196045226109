import { Container, Image } from "react-bootstrap";
import WhiteButton from "../buttons/WhiteButton";
import { useState } from "react";
import { InputValidator } from "../../modules/enums";
import { emailValidator } from "../../utils/services/validator.service";
import APIService from "../../utils/services/api.service";
import { Loading } from "../../utils/services/constants";
import toast from "react-hot-toast";

const AppStoreBanner = () => {
  const [email, setEmail] = useState({ value: "", errorState: false });
  const [mailInputError, setMailInputError] = useState(false);
  const [loading, setLoading] = useState(false);

   const validateSubscribe =  !email.value || email.errorState
    || emailValidator(email.value).validState === InputValidator.INVALID


    const handleFieldValidation = (data: string) => {
      const validatedEmail = emailValidator(data);
      setEmail({
        value: validatedEmail.validEmail,
        errorState: validatedEmail.validState !== InputValidator.VALID
      });
      setMailInputError(false);
    };


    const Subscribe = async () => {
      if (validateSubscribe) setMailInputError(true);
      else {
        setMailInputError(false);
        setLoading(true);
      }

      const payload = {
        email: email.value,
      }
      const {data: responseData} = await APIService.subscribe(payload);
      if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
      else {
            toast.success(responseData.message);
            setEmail({ value: '', errorState: false });
          }
       setLoading(false)
    }

  return(
    <Container className="app-store-banner">
      <div className="inner-container d-flex flex-column flex-lg-row justify-content-around">
        <div className="text-white m-auto">
          <h4 className="text-center text-lg-start w-600">
            Are you Driving? Moving? Or Making Deliveries For Your Business?
          </h4>
          <p className="font-size-16 text-center text-lg-start my-4 fw-light">Let’s get you started on the best experience possible</p>
          <div className="subscribe-container mx-auto mx-lg-0">
            <input type="email" placeholder="Email Address" className="subscribe-input" onChange={(e) => handleFieldValidation(e.target.value)} value={email.value}/>
            <WhiteButton
              btn={{
                text: "Get started",
                loadState: loading,
                clickFn: Subscribe
              }}
            />
          </div>
          {mailInputError && <span className="text-danger mt-2 font-size-12">Please enter a valid email address (e.g test@example.com)</span>}
        </div>
        <div className="image-container mx-auto">
          <Image src="https://storage.googleapis.com/public_storage_truq/website/subscribe_banner_oidsjdjd.jpg" alt="subscribe" width={300} height={350} className="banner-image"/>
        </div>
      </div>
    </Container>
  );
}

export default AppStoreBanner;

import {useEffect} from "react";
import {Container} from "react-bootstrap";

import JoinUs from "./atom/JoinUs";
import CareerHero from "./atom/hero/CareerHero";
import {AppTheme} from "../../modules/enums";
import {useTheme} from "../../components/hooks/useTheme";
import ProjectYou from "./atom/culturePillars/ProjectYou";
import CultureInfo from "./atom/culturePillars/CultureInfo";
import PageLayout from "../../components/layouts/PageLayout";
import WelcomedHere from "./atom/culturePillars/WelcomedHere";
import WeWorkTogether from "./atom/culturePillars/WeWorkTogether";
import BestPlaceToWork from "./atom/culturePillars/BestPlaceToWork";


const Career = () => {
  const appTheme = useTheme();

  useEffect(() => {
    if (appTheme.theme === AppTheme.LIGHT) appTheme.toggleTheme(AppTheme.DARK);
  }, [appTheme]);

  return (
    <PageLayout headerTheme={AppTheme.DARK} footerTheme={AppTheme.DARK}>
      <CareerHero/>
      <main className="career-article">
        <BestPlaceToWork/>
        <Container>
          <ProjectYou/>
          <WeWorkTogether/>
          <WelcomedHere/>
        </Container>
        <CultureInfo/>
        <JoinUs/>
      </main>
    </PageLayout>
  );
}

export default Career;

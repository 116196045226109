import { Terms } from "../../utils/mapper/terms";
import { AppTheme, LEGALS } from "../../modules/enums";
import Policy from "../../components/fragment/Policy";
import { useTheme } from "../../components/hooks/useTheme";

const ServiceTerms = () => {
  const appTheme = useTheme();
  if (appTheme.theme === AppTheme.DARK) appTheme.toggleTheme(AppTheme.LIGHT);

  return (
    <Policy policies={Terms} type={LEGALS.TERMS_CONDITION}
      description={`These terms and conditions ("Agreement") set forth the general terms and conditions of your use of the mytruq.com
     website/app and any of its related products and services. This Agreement is legally binding between the User and Us.
       By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound 
     by the terms of this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity, 
     you represent that you have the authority to bind such entity to this Agreement, in which case the term “User” shall refer to 
     such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, 
     you must not accept this Agreement and may not access and use the Website and Services. You acknowledge that this Agreement is 
     a contract between you and the Operator, even though it is electronic and is not physically signed by you, and it governs your use 
     of the Website and Services.`} />
  );
}

export default ServiceTerms;
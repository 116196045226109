import { Root, createRoot } from 'react-dom/client';
import App from './App';
import './assets/scss/style.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './components/hooks/useTheme';
import { NavContextProvider } from './components/hooks/useMobileNav';


const AppRoot = () => (
  <ThemeContextProvider >
    <NavContextProvider>
      <App />
    </NavContextProvider>
  </ThemeContextProvider>

);

const root: Root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppRoot />);
reportWebVitals();
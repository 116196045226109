import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {Container} from "react-bootstrap";

import {AppTheme} from "../../modules/enums";
import MoveSteps from "./atom/hero/MoveSteps";
import MoveExperience from "./atom/MoveExperience";
import TruqMoveHero from "./atom/hero/TruqMoveHero";
import {Loading} from "../../utils/services/constants";
import {IQuoteLoadState} from "../../modules/components";
import {useTheme} from "../../components/hooks/useTheme";
import APIService from "../../utils/services/api.service";
import {MoveTab, MoveTestimonies} from "../../utils/mapper/Mapper";
import Testimony from "../../components/fragment/Testimony";
import PageLayout from "../../components/layouts/PageLayout";
import {getBaseUrl} from "../../utils/services/axios.service";
import AppStoreBanner from "../../components/fragment/AppStoreBanner";
import MoveTypes from "./atom/MoveTypes";
import MoveSeamlessly from "./atom/MoveSeamlessly";

const MoveByTruq = () => {
  const appTheme = useTheme();
  const [loading, setLoading] = useState<IQuoteLoadState>({state: false, index: null});

  useEffect(() => {
    if (appTheme.theme === AppTheme.DARK) appTheme.toggleTheme(AppTheme.LIGHT);
  }, [appTheme]);

  const initiateQuote = async (type: string) => {
    setLoading({state: true, index: type})
    const {data: responseData} = await APIService.initiateTrip();
    if (!responseData || !responseData.status || responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      if (!responseData.data || !responseData.data.quote || !responseData.data.quote.reference) toast.error("unable to initiate quote at the moment, please try again later")
      else {
        const checkoutUrl = `${getBaseUrl()['checkout']}/${responseData.data.quote.reference}`;
        toast.success(responseData.message);
        const popupWindow = window.open(checkoutUrl, "_blank");
        if (popupWindow && (!popupWindow.closed || typeof popupWindow.closed === 'undefined')) popupWindow.focus();
      }
    }
    setLoading({state: false, index: type})
  }

  return (
    <PageLayout>
      <TruqMoveHero loadState={loading} getQuote={initiateQuote}/>
      <Container>
        <MoveTypes title="Move by truQ works for everyone" loadState={loading} getQuote={initiateQuote}/>
        <MoveExperience loadState={loading} getQuote={initiateQuote}/>
        <MoveSeamlessly loadState={loading} getQuote={initiateQuote}/>
        <MoveSteps title="Designed with you in mind!" data={MoveTab} loadState={loading} getQuote={initiateQuote}/>
      </Container>
      <Testimony testimonyData={MoveTestimonies}/>
      <AppStoreBanner/>
    </PageLayout>
  );
}

export default MoveByTruq;

import { useState, useRef, useEffect } from "react";
import { Scrollama, Step } from "react-scrollama";
import { Col, Container, Row} from "react-bootstrap";
import { IStackData } from "../../modules/components";
import { ColumnOrder } from "../../modules/enums";
import { AsyncImage } from "loadable-image";

type StackComponentProps = {
  steps: IStackData[];
  imgOrder?: ColumnOrder;
  enableScrollLock?: boolean;
};

const StackComponent = ({ steps, imgOrder, enableScrollLock }: StackComponentProps) => {
  const [image, setImage] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false); // To track image change
  const rightColumnRef = useRef<HTMLDivElement>(null);
  const leftColumnRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null); // Ref for the image element
  const [isScrollingLocked, setIsScrollingLocked] = useState<boolean>(false);

  // Handle step enter to update image
  const handleStepEnter = (index: number) => {
    setImage(steps[index].imageSrc);
    setImageLoaded(false); // Reset loaded state when image changes
  };

  // Observer for scroll lock
  useEffect(() => {
    if (!enableScrollLock) return;

    const leftColumn = leftColumnRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) setIsScrollingLocked(true);
          else setIsScrollingLocked(false);
        });
      },
      { threshold: 0.8}
    );

    if (leftColumn) observer.observe(leftColumn);

    return () => {
      if (leftColumn) observer.unobserve(leftColumn);
    };
  }, [enableScrollLock]);

  // Scroll lock implementation
  useEffect(() => {
    const handleScroll = (event: WheelEvent) => {
      if (!enableScrollLock) return;

      if (rightColumnRef.current && isScrollingLocked) {
        const col = rightColumnRef.current;
        const atBottom = col.scrollTop + col.clientHeight >= col.scrollHeight;
        const atTop = col.scrollTop === 0;

        const currentScrollDirection = event.deltaY > 0 ? "down" : "up";

        if (currentScrollDirection === "down" && !atBottom) {
          col.scrollTop += event.deltaY;
          event.preventDefault();
        } else if (currentScrollDirection === "up" && !atTop) {
          col.scrollTop += event.deltaY;
          event.preventDefault();
        } else setIsScrollingLocked(false);
      }
    };

    if (isScrollingLocked) window.addEventListener('wheel', handleScroll, { passive: false });
    else window.removeEventListener('wheel', handleScroll);

    return () => window.removeEventListener('wheel', handleScroll);
  }, [isScrollingLocked, enableScrollLock]);

  // Apply scale effect when the image changes
  useEffect(() => {
    if (imageRef.current) {
      setImageLoaded(true); // Trigger scale effect after image is loaded
    }
  }, [image]);

  return (
    <Container fluid>
      <Row className="mt-4 mt-lg-5">
        <Col
          lg={{ span: 6, order: imgOrder === ColumnOrder.FIRST ? 0 : 1 }}
          className="d-flex align-items-center justify-content-center my-3 my-lg-0 h-530"
          ref={leftColumnRef}
          style={{ position: "sticky", top: 0 }}
        >
          <AsyncImage
            src={image}
            style={{
              width: "100%",
              height: 430,
              transform: imageLoaded ? "scale(1.1)" : "scale(1)", 
              transition: "transform 0.08s ease-in-out",
              borderRadius: "20px"
            }}
            loader={<div style={{ background: '#888', width: "100%", height: 430 }} />}
            error={<div style={{ background: '#222', width: "100%", height: 430 }} />}
            onLoad={() => setImageLoaded(true)}
          />

        </Col>

        <Col
          lg={5}
          className={`${imgOrder === ColumnOrder.FIRST ? "ms-lg-5" : "me-lg-5"} h-530 no-scrollbar`}
          ref={rightColumnRef}
          style={{ overflowY: "auto" }}
        >
          <Scrollama onStepEnter={({ data }) => handleStepEnter(data - 1)}>
            {steps.map((item, index) => (
              <Step data={index + 1} key={index}>
                <div className={`${(enableScrollLock && index !== 0) ? "mt-md-150" : "mt-md-50"} my-5 my-lg-0 text-start`}>
                  <h5>{item.title}</h5>
                  <p>{item.description}</p>
                </div>
              </Step>
            ))}
          </Scrollama>
        </Col>
      </Row>
    </Container>
  );
};

export default StackComponent;

import {Card, Image} from 'react-bootstrap'
import { IImage } from '../../modules/components';

type Props = Pick<IImage, 'src' | 'alt'> & {
    cardClassName?: string,
    imgClassName?: string,
    text: string,
    header: string,
    headerClassName?: string,
  };

const MissionVisionCard = ({cardClassName, imgClassName, src, alt, header, headerClassName, text}: Props) => {
  return (
    <Card className={` ${cardClassName} w-100`} style={{ maxWidth: "450px", height: "350px" }}>
              <Card.Body className="d-flex flex-column justify-content-between">
                <Image src={src} alt={alt} className={`${imgClassName}`} />
                <div>
                  <h4 className={`card-title ms-0 fw-bold ${headerClassName}`}>{header}</h4>
                  <p className="text-medium font-size-16">{text}</p>
                </div>
              </Card.Body>
            </Card>
  )
}

export default MissionVisionCard

import { PolicyLinkPlacement, LinkType } from "../../modules/enums";
import {IPolicy} from "../../modules/components";

export const PrivacyPolicies: IPolicy[] = [
  {
    title: `Interpretation`,
    description: `The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in the plural.`,
  },
  {
    title: `Definitions`,
    description: `For the purposes of this Privacy Policy:`,
    extras: [
      {
        subTitle: `Account`,
        description: `means a unique account created for You to access our Service or parts of our Service.`
      },
      {
        subTitle: `Affiliate`,
        description: `means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for the election of directors or other managing authority.`
      },
      {
        subTitle: `Company`,
        description: `(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to truQ.`
      },
      {
        subTitle: `Cookies`,
        description: `are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.`
      },
      {
        subTitle: `Country`,
        description: `refers to Nigeria`
      },
      {
        subTitle: `Device`,
        description: `means any device that can access the Service such as a computer, a cell phone or a digital tablet.`
      },
      {
        subTitle: `Personal Data`,
        description: `is any information that relates to an identified or identifiable natural person.`
      },
      {
        subTitle: `Service`,
        description: `refers to the Website and Mobile Applications.`
      },
      {
        subTitle: `Service Provider`,
        description: `means any natural or artificial person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.`
      },
      {
        subTitle: `Third-party Service Providers`,
        description: `refers to any website or any social network website through which a User can log in or create an account to use the Service.`
      },
      {
        subTitle: `Usage Data`,
        description: `refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).`
      },
      {
        subTitle: `Website`,
        description: `refers to truQ, accessible from`,
        link: {
          href: `www.mytruq.com`,
          type: LinkType.TARGET,
          placement: PolicyLinkPlacement.DESCRIPTION
        }
      },
      {
        subTitle: `You`,
        description: `refers to the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.`
      }
    ]
  },
  {
    title: `Updates, Modifications & Amendments`,
    description: [
      `We may need to update, modify or amend our Privacy Policy as our technology evolves and as required by law. We reserve the right to make changes to this Privacy Policy from time to time and notify Users of material changes. The Privacy Policy will apply from the effective date provided on our website.`,
      `We advise that you check this page often, referring to the date of the last modification on the page. If a User objects to any of the changes to this Privacy Policy, the User must cease using this Site, or terminate their account in the event an account has been created.`
    ]
  },
  {
    title: `Age Restriction`,
    description: `Our website and services are not directed to children under 18. We do not knowingly collect information from children under 18. If as a parent or guardian, you become aware that your child or ward child has provided us with any information without your consent, please contact us through the details provided in this Privacy Policy.`,
  },
  {
    title: `Collecting and Using Your Personal Data`,
    subTitle: `Types of Data Collected`,
    extras: [
      {
        title: `Personal Data`,
        description: `While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. You will also be required to create a password to enable You gain access to portions of Our Website reserved for registered users. We do not request for billing information or passwords via email or telephone communication. Personally, identifiable information may include, but is not limited to:`,
        extra: [
          "Email address",
          "First name and last name",
          "Phone number",
          "Address, State, Province, ZIP/Postal code, City",
          "Billing information"
        ]
      },
      {
        title: `Usage Data`,
        description: `Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address, gps location, browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.`, footer: [
          `When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data. We may also collect information that Your browser sends whenever You visit our service or when you access the service by or through a mobile device.`,
          `We may also collect information that Your browser sends whenever You visit our service or when you access the service by or through a mobile device.`
        ]
      }
    ],
  },
  {
    title: `Tracking Technologies and Cookies`,
    description: `We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information such as your authentication token to enable automatic login for example. 
      You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of Our Service. 
      Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.We use both session and persistent Cookies for the purposes set out below:`,
    extras: [
      {
        title: `Necessary / Essential Cookies`,
        extra: [
          `Type: Session Cookies`,
          `Administered by: Us`,
          `Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.`
        ]
      },
      {
        title: `Cookies Policy / Notice Acceptance Cookies`,
        extra: [
          `Type: Persistent Cookies`,
          `Administered by: Us`,
          `Purpose: These Cookies identify if users have accepted the use of Cookies on the Website.`
        ]
      },
      {
        title: `Functionality Cookies`,
        extra: [
          `Type: Persistent Cookies`,
          `Administered by: Us`,
          `Purpose: These cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.`
        ]
      }
    ],
    footer: `For more information about the Cookies. We use and your choices regarding Cookies, please visit our Cookies Policy.`
  },
  {
    title: `Use of Your Personal Data`,
    extras: [
      {
        title: `The Company may use Personal Data for the following purposes.`,
        extra: [
          `To provide and maintain our Service,including monitoring the usage of our Service.`,
          `To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.`,
          `To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.`,
          `To provide You with news, special offers and general information about other goods, services and events that we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.`,
          `To manage Your requests: To attend and manage Your requests to Us`,
          `To collect fees`
        ],
      },
      {
        description: `We may share your personal information in the following situations:`,
        extra: [
          `With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.`,
          `For Business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.`,
          `With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.`,
          `With Business partners: We may share Your information with Our business partners to offer You certain products, services or promotions`,
        ]
      },
    ]
  },
  {
    title: `Retention of Your Personal Data`,
    description: `The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.`,
    footer: `The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.`
  },
  {
    title: `Updating Your Personal Data`,
    description: `We strive to keep the records of Our users as accurate as possible. We request that You update your account information when it changes. We will not be held liable for any errors made if You fail to update the information.`
  },
  {
    title: `Withdrawing Your Consent`,
    description: `We offer You certain choices about how we communicate with You and what information we collect from You. You may choose not to receive marketing email communications from us by clicking on the unsubscribe link in our marketing emails, adjusting your email preferences or contacting us as specified in the "Contact Us" section below and we will honor Your request. Further, as required by law, you may object at any time on legitimate grounds and free of charge to the processing of Your personal information, and we will apply Your preferences going forward. Additionally, You may delete your account if you wish to discontinue use of our service.`
  },
  {
    title: `Transfer of Your Personal Data`,
    description: [
      `Your information, including Personal Data, is processed at the Company's operating offices in Nigeria and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those from Your jurisdiction.`,
      `Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.`,
      `The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.`
    ]
  },
  {
    title: `Disclosure of Your Personal Data`,
    extras: [
      {
        title: `Business Transactions`,
        description: `If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.`
      },
      {
        title: `Law enforcement`,
        description: `Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).`
      },
      {
        title: `Other legal requirements`,
        description: `The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:`,
        extra: [
          `Comply with a legal obligation`,
          `Protect and defend the rights or property of the Company`,
          `Prevent or investigate possible wrongdoing in connection with the Service`,
          `Protect the personal safety of Users of the Service or the public`,
          `Protect against legal liability`
        ]
      },
    ]
  },
  {
    title: `Security of Your Personal Data`,
    description: `We have taken strong measures to protect the security of your personal information and to ensure that your choices for its intended use are honored. We take strong precautions to protect your data from loss, misuse, unauthorized access or disclosure, alteration, or destruction.`
  },
  {
    title: `Detailed Information on the Processing of Your Personal Data`,
    description: `Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.`
  },
  {
    title: `Usage, Performance and Miscellaneous`,
    description: `We may use third-party Service Providers to provide better improvement of our Service.`,
    extras: [
      {
        title: `Google Places`,
        link: {
          href: `https://www.google.com/intl/en/policies/privacy/`,
          type: LinkType.TARGET,
          placement: PolicyLinkPlacement.MORE
        },
        extra: [
          `Google Places is a service that returns information about places using HTTP requests. It is operated by Google.`,
          `Google Places service may collect information from You and from Your Device for security purposes.`,
          `The information gathered by Google Places is held in accordance with the Privacy Policy of Google:`
        ]
      },
      {
        title: `Paystack:`,
        description: `Online payment gateway that makes it easy to accept credit and debit card payments online from You. The information gathered by Paystack is held in accordance with the Privacy Policy of Paystack:`,
        link: {
          href: `https://paystack.com/terms`,
          type: LinkType.TARGET,
          placement: PolicyLinkPlacement.DESCRIPTION
        },
      }
    ]
  },
  {
    title: `Links to Other Websites`,
    description: `This Privacy Policy applies only to this Website. Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.`,
    footer: `We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.`
  },
  {
    title: `Contact Us`,
    description: `If for some reason You believe the Company has not adhered to these principles, please notify Us by emailing contact@mytruq.com, and We will do Our best to determine and correct the problem promptly. Be certain the words “Privacy Policy” are in the Subject line.`,
    footer: `Furthermore, if You have any questions about this Privacy Policy, You can contact Us:`,
    link: {
      href: `contact@mytruq.com`,
      type: LinkType.EMAIL,
      placement: PolicyLinkPlacement.FOOTER
    }
  },
]

import {useEffect} from "react";
import {Container} from "react-bootstrap";

import TruqStars from "./atom/TruqStars";
import AboutUSHero from "./atom/hero/AboutUSHero";
import CoreBeliefs from "./atom/CoreBeliefs";
import {AppTheme} from "../../modules/enums";
import NewsFeatures from "./atom/NewsFeatures";
import {useTheme} from "../../components/hooks/useTheme";
import PageLayout from "../../components/layouts/PageLayout";
import PrinciplesOfManagement from "./atom/PrinciplesOfManagement";


const AboutUs = () => {
  const appTheme = useTheme();

  useEffect(() => {
    if (appTheme.theme === AppTheme.LIGHT) appTheme.toggleTheme(AppTheme.DARK);
  }, [appTheme]);


  return (
    <PageLayout headerTheme={AppTheme.DARK} footerTheme={AppTheme.DARK}>
      <Container className="my-5 mt-md-70">
        <AboutUSHero/>
        <CoreBeliefs/>
        <PrinciplesOfManagement/>
        <TruqStars/>
        <NewsFeatures/>
      </Container>
    </PageLayout>
  );
}

export default AboutUs;

import VisionSvg from "../../../assets/images/svgs/vsion.svg";
import MissionSvg from "../../../assets/images/svgs/mission.svg";
import MissionVisionCard from "../../../components/fragment/MissionVisionCard";

const PrinciplesOfManagement = () => (
  <section className="px-4 py-5 mt-5 mt-md-130 mt-xxl-180">
    <div className="d-flex flex-column flex-md-row justify-content-center row-gap-4 column-gap-3 ">
      <MissionVisionCard
              cardClassName="bg-lighter-orange"
              imgClassName="icon-avatar-mission bg-primary-blue"
              src={MissionSvg}
              alt="mission"
              header="Our Mission" 
              headerClassName="w-50"
              text="To empower commercial fleet operators with everything they need to operate a thriving business and find fulfilment in their hustle."
      />
      <MissionVisionCard
              cardClassName="bg-light-blue"
              imgClassName="icon-avatar-vision bg-lighter-yellow"
              src={VisionSvg}
              alt="vision"
              header="Our Vision" 
              text="We envision a pan-African future where every logistics-related economic and commercial activity is faster, smarter, and better than ever because of the work we do at truQ"
      />
    </div>
  </section>
)

export default PrinciplesOfManagement;

import GetStartedButton from "../../../components/buttons/GetStartedButton";
import StackComponent from "../../../components/fragment/Stack";
import { ColumnOrder } from "../../../modules/enums";
import { LogisticsOperations } from "../../../utils/mapper/Mapper";

const SijuExperience = ({ handleClick }: { handleClick: () => void }) => {

  return (
    <section className="px-4 my-5 d-flex flex-column">
      <h3 className="text-center w-1000 w-100 align-self-center">
        Explore game-changing logistics operations on Siju Designed to help your business win.
      </h3>
      <StackComponent steps={LogisticsOperations} imgOrder={ColumnOrder.SECOND} enableScrollLock={true}/>
      <div className="mx-2 mt-lg-3 mt-0">
        <GetStartedButton />
      </div>
    </section>

  )
}
export default SijuExperience

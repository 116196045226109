import { useNavigate } from "react-router-dom";
import { ErrorNavType } from "../../modules/enums";
import {Container, Image} from "react-bootstrap";
import PrimaryButton from "../buttons/PrimaryButton";

type Props = {
  title: string,
  image: string,
  imageAlt: string,
  navigateType: ErrorNavType,
  description: string,
}

const ErrorLayout = ({ title, image, imageAlt, navigateType, description }: Props) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (navigateType === ErrorNavType.G0_BACK) navigate(-1);
    else navigate('/');
  }

  return (
    <section>
      <Container className="d-flex flex-column justify-content-center text-center" style={{ minHeight: "100vh", padding: "40px 10px 10px" }}>
        <div style={{ maxWidth: "500px" }} className="mx-auto mb-5"><Image alt={imageAlt} src={image} height={300} /></div>
        <h2 className="mt-4">{title}</h2>
        <p style={{ maxWidth: "500px" }} className="align-self-center mt-3">{description}</p>
        <PrimaryButton
          btn={{
            text: navigateType === ErrorNavType.G0_BACK ? "Go Back" : "Go to Home Page",
            btnClassName: 'align-self-center mt-5 px-4',
            clickFn: handleNavigation,
          }}
        />
      </Container>
    </section>
  );
}

export default ErrorLayout;

import { useTheme } from "../../components/hooks/useTheme";
import { AppTheme, ErrorNavType } from "../../modules/enums";
import ErrorLayout from "../../components/layouts/ErrorLayout";
import ImageSVG from "../../assets/images/svgs/error403.svg";
import ImageWhiteSVG from "../../assets/images/svgs/error403-white.svg";

const Error403 = () => {
  const appTheme = useTheme();

  return (
    <ErrorLayout title="Unauthorized"
      image={appTheme.theme === AppTheme.DARK ? ImageSVG : ImageWhiteSVG}
      imageAlt="UNAUTHORIZED"
      navigateType={ErrorNavType.G0_BACK}
      description={`Access Denied! Sorry, but you do not have permission to access this page, 
            Please contact the website administrator for assistance or return to the homepage`} />
  );
}

export default Error403;
import {Container, Image} from "react-bootstrap";

import TextLoop from "../../../../components/fragment/TextLoop";
import CoinsSVG from "../../../../assets/images/svgs/coins.svg";
import walletSVG from "../../../../assets/images/svgs/wallet.svg";
import SuccessSVG from "../../../../assets/images/svgs/Successful.svg";
import DocumentSVG from "../../../../assets/images/svgs/document-text.svg";
import DriveHubActions from "../../../../components/buttons/DriveHubActions";

const DriveHubHero = ({handleClick}: { handleClick: () => void }) => {
  return (
    <section className="mb-0 driveHub-hero">
      <Container className="d-flex flex-column flex-wrap text-center">
        <div className="text-center">
          <h2 className="text-indigo">Get Access To
            <TextLoop className="mx-2 text-orange text-center"
                      data={[
                        "The Financing", "The Support", "The Services", "The Community", "The Discounts", "Everything"
                      ]}/>
            <br/>
            You Need To Grow Your Logistics Hustle.
          </h2>
          <DriveHubActions className="my-5" handleClick={handleClick}/>
        </div>
        <div className="position-relative mt-md-70 my-50">
          <div className="success-message success-message-transfer">
            <div className="text-start">
              {/* <p className="success-header">Quick Payment</p> */}
              <small className="font-size-14 fw-bold">Quick Payment</small>
            </div>
            <Image className="ms-3" alt="successful" src={SuccessSVG} width={30} height={30}/>
          </div>
          <div className="success-message success-message-renewal">
            <div className="text-start">
              <small className="font-size-14 fw-bold">Document Renewal</small>
              {/* <p className="text-start success-header">You document has been uploaded</p> */}
            </div>
            <Image className="ms-2" alt="successful" src={DocumentSVG} width={20} height={20}/>
          </div>
          <div className="points-notification points-notification-points">
            <div className="text-start">
              {/* <p className="points-header">You have earned</p> */}
              <small className="font-size-14 fw-bold">Buy sparepart</small>
            </div>
            <Image alt="coins" src={CoinsSVG} width={20} height={20} className="my-auto ms-3"/>
          </div>
          <div className="points-notification points-notification-topUp">
            <div className="text-start">
              <small className="font-size-14 mb-1 fw-bold">Quick Topup</small>
              {/* <p className="topUp-text w-150">Your Quick Topup has been approved</p> */}
            </div>
            <Image alt="coins" src={walletSVG} width={20} height={20} className="my-auto ms-1"/>
          </div>
        </div>
        <div className="hero_container align-self-center mt-md-70">
          <Image
            alt="TRUQAPP_HERO"
            src="https://storage.googleapis.com/public_storage_truq/website/driverapp_hero_fjbskj.jpeg"
          className="img-fluid mt-5 object-fit-cover"
          />
        </div>
      </Container>
    </section>
  )
}
export default DriveHubHero

import {useEffect} from "react";
import {Container} from "react-bootstrap";

import SijuHero from "./atom/hero/SijuHero";
import SijuSteps from "./atom/SijuSteps";
import {AppTheme} from "../../modules/enums";
import SijuExperience from "./atom/SijuExperience";
import Brands from "../../components/fragment/Brands";
import {useTheme} from "../../components/hooks/useTheme";
import {SijuTab, SijuTestimonies} from "../../utils/mapper/Mapper";
import Testimony from "../../components/fragment/Testimony";
import PageLayout from "../../components/layouts/PageLayout";
import AppStoreBanner from "../../components/fragment/AppStoreBanner";

const Siju = () => {
  const appTheme = useTheme();

  useEffect(() => {
    if (appTheme.theme === AppTheme.DARK) appTheme.toggleTheme(AppTheme.LIGHT);
  }, [appTheme]);

  const handleClick = () => window.open("https://app.siju.it/auth/register");

  return (
    <PageLayout>
      <SijuHero handleClick={handleClick}/>
      <Brands title="Customers that trust us" description="Siju is trusted by the businesses doing logistics across several industries (Businesses serviced by truQ)"/>
      <Container className="mt-4 mt-md-70">
        <SijuExperience handleClick={handleClick}/>
        <SijuSteps title="Join the league of ambitious businesses trusting siju to tackle their logistics hurdles, expedite deliveries, and drive their enterprises forward." data={SijuTab}/>
      </Container>
      <Testimony testimonyData={SijuTestimonies}/>
      <AppStoreBanner/>
    </PageLayout>
  );
}

export default Siju;

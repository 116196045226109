import { AppTheme, ErrorNavType } from "../../modules/enums";
import { useTheme } from "../../components/hooks/useTheme";
import ErrorLayout from "../../components/layouts/ErrorLayout";
import ImageSVG from "../../assets/images/svgs/error404.svg";
import ImageWhiteSVG from "../../assets/images/svgs/error404-white.svg";

const Error404 = () => {
  const appTheme = useTheme();

  return (
    <ErrorLayout title="Page not found"
      image={appTheme.theme === AppTheme.DARK ? ImageSVG : ImageWhiteSVG}
      imageAlt="NOT-FOUND"
      navigateType={ErrorNavType.HOME}
      description={`Oops! The page you're looking for cannot be found. It seems like the 
      link might be broken or the page has been removed. 
      Please double-check the URL or return to the homepage`}/>
  );
}

export default Error404;
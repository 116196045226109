import { Spinner } from "react-bootstrap";
import { IButtons } from "../../modules/components";

const PrimaryButton = ({ btn }: { btn: IButtons }) => (
  <button onClick={btn.clickFn} disabled={btn.disabledState}
    className={`btn ${btn.outline ? "btn-outline-primary" : "btn-primary"} ${btn.btnClassName}`}>
    {btn.image && <img src={btn.image.imgSrc} alt={btn.image.imgAlt ?? 'ICON'}
      className={`${btn.image.imgClass ? btn.image.imgClass : 'me-1'}`} width={btn.image.width ?? 24} height={btn.image.height ?? 24} />
    }
    {btn.text}
    {btn.loadState &&  <Spinner animation="border" size="sm" className="ms-2" color="white"/>}
  </button>
);

export default PrimaryButton;

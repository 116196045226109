import { ReactElement, createContext, useContext, useState } from "react";
import { AppTheme } from "../../modules/enums";

type ThemeContextType = {
  theme: AppTheme.LIGHT | AppTheme.DARK,
  toggleTheme: (theme?: AppTheme) => void,
}

export const ThemeContext = createContext<ThemeContextType>({
  theme: AppTheme.LIGHT,
  toggleTheme: (theme?: AppTheme) => { },
});

export const useTheme = () => useContext(ThemeContext);

export const ThemeContextProvider = ({ children }: { children: ReactElement }) => {
  const [theme, setTheme] = useState(AppTheme.LIGHT);

  const toggleTheme = (themeParams?: AppTheme) => {
    if (themeParams) {
      updateBodyBackground(themeParams);
      setTheme(themeParams);
    }
    else {
      if (theme === AppTheme.DARK) setTheme(AppTheme.LIGHT);
      else setTheme(AppTheme.DARK);
      updateBodyBackground(theme);
    }
  }

  const updateBodyBackground = (theme: AppTheme) => {
    if (theme === AppTheme.DARK) {
      document.body.classList.add('body-dark');
      document.body.classList.remove('body-light');
    } else {
      document.body.classList.add('body-light');
      document.body.classList.remove('body-dark');
    }
  }

  const value = { theme, toggleTheme };

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};


import { Col } from "react-bootstrap";
import { IImage, IQuoteLoadState } from "../../modules/components";
import GetStartedButton from "../buttons/GetStartedButton";
import PrimaryButton from "../buttons/PrimaryButton";
import { AsyncImage } from "loadable-image";

type Props = IImage & {
  title: string;
  description: string;
  loadState?: IQuoteLoadState;
  getQuote?: (type: string) => void;
  showGetQuote?: boolean
};

const CardText = ({ src, alt, title, description, loadState, getQuote, showGetQuote }: Props) => (
  <Col lg={5}>
    <div style={{ height: "500px" }}>
      <AsyncImage
        src={src}
        alt={alt}
        className="curved-images"
        style={{ width: "100%", height: "100%" }}
        loader={<div style={{ background: '#888' }} />}
        error={<div style={{ background: '#222' }} />}
      />
    </div>
    <div className="mt-4 text-start">
      <h5 className="line-spacing-05 text-dark">{title}</h5>
      <p className="text-medium text-start mt-2">{description}</p>
    </div>
    {showGetQuote !== undefined && <div className="d-flex gap-4 my-3  align-self-start">
      <GetStartedButton segment={showGetQuote !== true ? "PARTNER" : ""} />
      {showGetQuote &&
        <PrimaryButton
          btn={{
            text: "Check Quote",
            btnClassName: "px-4 border border-primary",
            outline: true,
            loadState: (loadState?.state && loadState?.index === 'quote'),
            clickFn: () => getQuote?.("quote")
          }}
        />}
    </div>}
  </Col>
);

export default CardText

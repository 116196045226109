import {useEffect} from "react";
import {Container, Row} from "react-bootstrap";
import {AppTheme} from "../../modules/enums";
import ContactInfo from "./atom/ContactInfo";
import ContactForm from "./atom/ContactForm";
import {useTheme} from "../../components/hooks/useTheme";
import PageLayout from "../../components/layouts/PageLayout";

const ContactUs = () => {
  const appTheme = useTheme();

  useEffect(() => {
    if (appTheme.theme === AppTheme.DARK) appTheme.toggleTheme(AppTheme.LIGHT);
  }, [appTheme]);

  return (
    <PageLayout >
      <Container className="mt-5 mt-md-70">
        <Row className="justify-content-center align-self-center my-5">
          <ContactInfo/>
          <ContactForm/>
        </Row>
      </Container>
    </PageLayout>
  )
}

export default ContactUs

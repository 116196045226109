import { AppTheme, LEGALS } from "../../modules/enums";
import Policy from "../../components/fragment/Policy";
import { useTheme } from "../../components/hooks/useTheme";
import { DriverPartnerTerms } from "../../utils/mapper/driverPartnerTerms";

const PartnerTerms = () => {
  const appTheme = useTheme();
  if (appTheme.theme === AppTheme.DARK) appTheme.toggleTheme(AppTheme.LIGHT);

  return (
    <Policy policies={DriverPartnerTerms} type={LEGALS.TERMS_DRIVER_PATNER}
      description={`Terms and Conditions for signing up as a Driver-Partner on truQ Please read these Terms carefully before fully signing up as
                  Driver-Partners by clicking to accept the terms stated here. Your confirmation as a Driver-Partner by truQ (the “Company”)
                  is conditioned on your acceptance of and compliance with these
                  Terms. These Terms apply to all Driver-Partners subscribing to
                  our platform. truQ reserves the right to change the Driver Partner Terms at
                  any time at our sole discretion. Please check these terms
                  regularly for changes. Your continued use of the Platform
                  following the posting of any changes to the Terms will
                  constitute your acceptance of and agreement to those changes.
                  By accepting these terms, you agree to be bound by these
                  Terms. If you disagree with any part of the terms you are
                  advised against accepting. Our collection and use of personal
                  information in connection with the Terms and Conditions are
                  provided in truQ's Privacy Policy on the website.`}
      footer={{
        title: `I CONFIRM THAT I HAVE READ AND UNDERSTOOD ALL THE TERMS AND CONDITIONS, AND I CONSENT TO BE BOUND BY SAME.`,
        subTitle: `This document was last updated on November 23rd, 2022.`
      }} />
  );
}

export default PartnerTerms;
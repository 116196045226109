import {Nav, Tab} from "react-bootstrap";
import {IProduct} from "../../modules/components";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../buttons/PrimaryButton";

type Props = {
  data: IProduct[],
  setActiveTabKey: (key: string) => void;
}
const Tabs = ({data, setActiveTabKey}: Props) => {
  const navigate = useNavigate();

  const handleSelect = (key: string | null) => {
    if (key) {
      setActiveTabKey(key); 
    }
  };
  
  return (
  <Tab.Container defaultActiveKey={data[0].key} onSelect={handleSelect}>
    <Nav variant="pills" className="mb-4">
      {data.map(tab => <Nav.Item key={tab.key}><Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link></Nav.Item>)}
    </Nav>

    {data.map(tab =>
      <Tab.Content className="mt-2 mb-3">
        <Tab.Pane eventKey={tab.key}>
          <h5 className="text-primary mb-3">{tab.title}</h5>
          {tab.description && <p className="text-medium">{tab.description}</p>}
          <ul className="tabs-list">
            {tab.details.map((item, index) =>
              <li key={index} className="">{item.text}</li>
            )}
          </ul>
          <PrimaryButton
            btn={{
              text: tab.link.text,
              outline: true,
              btnClassName: 'btn-block mb-4 mt-2 font-weight-400 border border-primary-subtle',
              clickFn: () => navigate(`${tab.link.href}`)
            }}
          />
        </Tab.Pane>
      </Tab.Content>
    )}
  </Tab.Container>
)}

export default Tabs;

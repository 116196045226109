import {Container} from "react-bootstrap";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import {MenuType} from "../../../modules/enums";
import {useNavigate} from "react-router-dom";

const JoinUs = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <section className="my-5 mt-md-130 mb-md-110 p-3 p-md-0">
        <div className="newsletter px-3 px-md-0">
          <div className="w-507 w-100 py-4 py-lg-1">
            <h4 className="text-center">Join the truQStars</h4>
            <p className="font-size-14 text-center mt-1 mb-3 px-5 max-w-320 mx-auto">
              Be a part of a team that is driving the future of logistics in Africa
            </p>
            <div className="d-flex justify-content-center mt-4">
              <PrimaryButton
                btn={{
                  text: "View Open Roles",
                  btnClassName: 'my-3',
                  clickFn: () => navigate(`/${MenuType.CONSTRUCTION}`),
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default JoinUs;

import {Fragment} from 'react';
import {Image} from "react-bootstrap";
import {NavLink} from 'react-router-dom';
import {LinkType} from '../../modules/enums';
import {IHeader} from "../../modules/components";

const NavLinkInfo = ({alt, src, title, subtitle, className}: Omit<IHeader, "link" | "link_type">) => (
  <div className='w-299 d-flex  my-3 cursor-pointer'>
    <div className='header-icon'><Image alt={alt} src={src}/></div>
    <div className='ms-3 d-inline-flex flex-column'>
      <p className={`header-title ${className}`}>{title}</p>
      <p className={`header-subtitle ${className}`}>{subtitle}</p>
    </div>
  </div>
)


const NavItem = ({link, link_type, alt, src, title, subtitle, className}: IHeader) => (
  <Fragment>
    {link_type === LinkType.IN_APP
      ? <NavLink to={link}> {NavLinkInfo({alt, src, title, subtitle, className})}</NavLink>
      : <a href={link} target="_blank" rel="noreferrer"> {NavLinkInfo({alt, src, title, subtitle, className})}</a>
    }
  </Fragment>
);

export default NavItem

import {Col, Image} from "react-bootstrap";
import Phone from "../../../assets/images/pngs/telephone.png";

const ContactInfo = () => (
  <Col lg={6} className="text-start ms-4 ms-lg-0">
    <h5 className="text-darker font-size-30 font-weight-700 mt-2 ms-0 mb-3">
      Contact Us <Image src={Phone} alt="telephone" width={30} height={24} className="ms-2"/>
    </h5>
    <p className="text-medium mb-4 font-size-16 font-weight-400">
      We are passionate executors but even better listeners!
    </p>
    <h5 className="text-darker font-size-20 font-weight-700 ms-0 mb-2">Address</h5>
    <p className="text-medium mb-4 font-size-16 font-weight-400">truQ, Zone tech park, Gbagada</p>
    <h5 className="text-darker font-size-20 font-weight-700 ms-0 mb-2">Phone Number</h5>
    <a href="tel:+2348168735873" className="text-medium mb-4 font-size-16 font-weight-400">+2348168735873</a>
    <h5 className="text-darker font-size-20 font-weight-700 ms-0 mb-2 mt-4">Email Address</h5>
    <a href="mailto:contact@mytruq.com" className="text-medium font-size-16 font-weight-400">contact@mytruq.com</a>
  </Col>
)
export default ContactInfo;

import StackComponent from "../../../components/fragment/Stack";
import { ColumnOrder } from "../../../modules/enums";
import { DriverEmpowerment } from "../../../utils/mapper/Mapper";

const DriveHubEmpowerment = () => {

  return (
  <section className="text-center" style={{margin: "100px 0"}}>
    <div className="align-self-center">
      <h3>Empower your drive with truQ</h3>
    </div>
    <StackComponent steps={DriverEmpowerment} imgOrder={ColumnOrder.FIRST} enableScrollLock={true}/>
  </section>
)}
export default DriveHubEmpowerment

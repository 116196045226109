import {Container, Row} from "react-bootstrap";
import CardText from "../../../../components/fragment/CardText";

const CultureInfo = () => (
  <section className="culture-info">
    <Container className="p-0 p-md-5">
      <Row className="d-flex justify-content-center gap-5">
        <CardText alt="CULTURE_3" className="w-100 rounded-4 h-100" title="Result Over Activities"
                  description={`It's either you are delivering the results or not, activities count for nothing! We do whatever it takes to get the desired result, period`}
                  src="https://storage.googleapis.com/public_storage_truq/website/career_culture_info_1_pasgfurd.jpg"
        />
        <CardText alt="CULTURE_4" className="w-100 rounded-4 h-100" title="We Are Customer Obsessed"
                  description={`We are unapologetically obsessed with our customers, doing all we can at every point to ensure their needs are met with the best experience possible`}
                  src="https://storage.googleapis.com/public_storage_truq/website/career_culture_info_2_hcsjdusks.jpg"
        />
      </Row>
    </Container>
  </section>
)

export default CultureInfo

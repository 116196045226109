import { ReactElement } from "react";
import Header from "./header/Header";
import Footer from "./Footer";
import { AppTheme } from "../../modules/enums";


type Props = {
  children: ReactElement | ReactElement[],
  headerTheme?: AppTheme,
  footerTheme?: AppTheme,
}

const PageLayout = ({ children, headerTheme = AppTheme.LIGHT, footerTheme = AppTheme.LIGHT }: Props) => (
  <main className="main-body">
    <Header theme={headerTheme} />
    <section className="page-layout">
        {children}
    </section>
    <Footer theme={footerTheme} />
  </main>
);

export default PageLayout;
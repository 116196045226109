import Slider from "react-slick";
import {Image} from "react-bootstrap";
import { ITestimonyData } from "../../modules/components";


type Props = {
  title?: string;
  description?: string;
  testimonyData: ITestimonyData[],
};

const Testimony = ({title, description, testimonyData }: Props) =>{

  const ReactSlickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 5,
    cssease: "ease",
    pauseOnHover: true,
    className: "container",
    centerMode: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        }
      }
    ]
  }

  return(
  <section className="d-flex flex-column">
    <div className="bg-light d-flex flex-column py-30">
      <div className="d-flex flex-column align-self-center m-3">
        <h4 className="text-darker text-center">{title ?? `Don't take our word for it`}</h4>
        <p className="text-medium text-center my-2 mx-auto w-507">
          {description ?? `Check out what our Partners are saying about us`}
        </p>
      </div>
      <div className="d-flex justify-content-center my-4">
        <Slider {...ReactSlickSettings}>
          {testimonyData.map((data, index) =>
            <div key={index} className="px-2">
              <div className="card h-100">
                <div className="card-body">
                  <span className="testimony-text">{data.content}</span>
                  <div className="d-flex gap-3 mt-3">
                    {data.image
                      ? <Image
                        alt="lazy"
                        src={data.image}
                        className="rounded-circle"
                        width="40" height="40"
                      />
                      : <div style={{ width: "40px", height: "40px"}}
                        className={`d-flex justify-content-center align-items-center rounded-circle ${data.imageBg}`}>
                            {data.author[0].toUpperCase()}
                        </div>
                    }

                    <div className="my-1">
                      <p className="font-size-14 fw-bold">{data.author}</p>
                      <small className="font-size-12">{data.medium}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Slider>
      </div>
    </div>
  </section>
);}

export default Testimony;

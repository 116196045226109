import {Row} from "react-bootstrap";
import CardText from "../../../components/fragment/CardText";
import { IQuoteLoadState } from "../../../modules/components";

type Props = {
  title: string;
  loadState: IQuoteLoadState;
  getQuote: (type: string) => void;
}

const MoveTypes = ({title, loadState, getQuote}: Props) => (
  <section className="mt-md-100">
    <h3 className="text-center my-5">{title}</h3>
    <Row className="d-flex justify-content-center gap-5">
      <CardText alt="CULTURE_3" className="w-100 rounded-4 h-100" title="Move by truQ for Individuals" height="450"
                description={`Access seamless logistics and home maintenance services, before during and after your Move`}
                src="https://storage.googleapis.com/public_storage_truq/website/move_types_1_nbvdkdhs.jpg"
                loadState={loadState} getQuote={getQuote} showGetQuote={true}/>
      <CardText alt="CULTURE_4" className="w-100 rounded-4 h-100" title="Move by truQ for Partners" height="450"
                description={`Make extra revenue by offering an all-in-one seamless logistics and home maintenance services to your customers powered by Move.`}
                src="https://storage.googleapis.com/public_storage_truq/website/move_types_2_mnghddks.jpg"
                loadState={loadState} getQuote={getQuote} showGetQuote={false}/>
    </Row>
  </section>
)
export default MoveTypes;

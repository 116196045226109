import { dodgeInstance as Axios, backOfficeInstance as Backoffice} from "./axios.service";
import { AxiosResponse } from "axios";
import {IMessageRequest, IOnboard, ISubscribe} from "../../modules/components";

const APIService = {
  initiateTrip(): Promise<AxiosResponse> {
    return Axios({
      method: 'POST',
      url: `booking-service/booking/booking-trip/check_quote/`,
    });
  },

  sendMessage(payload: IMessageRequest): Promise<AxiosResponse> {
    return Backoffice({
      method: 'POST',
      url: `/messaging-service/messaging/contact-us`,
      data: payload
    });
  },

  subscribe(payload: ISubscribe): Promise<AxiosResponse> {
    return Backoffice({
      method: 'POST',
      url: `/messaging-service/subscribers/subscribe`,
      data: payload
    });
  },

  onboardLead(payload: IOnboard): Promise<AxiosResponse> {
    return Backoffice({
      method: 'POST',
      url: `/integration-service/onboarding`,
      data: payload
    });
  },

  getSupportedCountries() {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/countries`,
    });
  },

}

export default APIService;

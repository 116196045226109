import { AppTheme, LEGALS } from "../../modules/enums";
import Policy from "../../components/fragment/Policy";
import { useTheme } from "../../components/hooks/useTheme";
import { PrivacyPolicies } from "../../utils/mapper/privacyPolicy";

const PrivacyPolicy = () => {
  const appTheme = useTheme();
  if (appTheme.theme === AppTheme.DARK) appTheme.toggleTheme(AppTheme.LIGHT);

  return (
    <Policy policies={PrivacyPolicies} type={LEGALS.PRIVACY_POLICY}
      description={`truQ, along with our subsidiaries and affiliates (collectively truQ), respect Your concerns about privacy. This Privacy Policy describes truQ’s policies and procedures on the collection, use, and disclosure of personal information  You either provide directly to Us or that We receive from Your authorized third parties when You use the Service,
  and tells You about Your privacy rights and how the law protects You. We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
  The words of which the initial letter is capitalized have meanings defined under the following conditions.
  The following definitions shall have the same meaning regardless of whether they appear in singular or in the plural.`} />
  );
}

export default PrivacyPolicy;
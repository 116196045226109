import { Row, Tab, Nav, Image } from "react-bootstrap";
import { IQuoteLoadState, ITab } from "../../../../modules/components";
import GetStartedButton from "../../../../components/buttons/GetStartedButton";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";

type Props = {
  title: string;
  data: ITab[];
  loadState: IQuoteLoadState;
  getQuote: (type: string) => void
}
const MoveSteps = ({ title, data, loadState, getQuote }: Props) => {
  return (
    <section className="my-5 mt-md-130">
      <h3 className={`text-center`}>{title}</h3>
      <Tab.Container defaultActiveKey="1">
        <Row className="mt-5">
          <div className="col-md-6 col-xl-5 mb-3 h-100">
            <Nav variant="underline" className="flex-column nav-underline">
              {data.map((tab) => (
                <Nav.Item key={tab.id}>
                  <Nav.Link eventKey={tab.id.toString()}>{tab.title}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
          <div className="col-md-6 col-xl-7 mb-3 h-100">
            <Tab.Content className="tab-content-container p-3">
              {data.map((tab) => (
                <Tab.Pane key={tab.id} eventKey={tab.id.toString()}>
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <Image src={tab.src} alt={tab.alt} />
                  </div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </div>
        </Row>
      </Tab.Container>
      <div className="mx-md-5 my-2 d-flex justify-content-start w-400">
        <GetStartedButton />
        <PrimaryButton
          btn={{
            text: "Check Quote",
            btnClassName: "px-4 ms-3 border border-primary",
            outline: true,
            loadState: (loadState.state && loadState.index === 'quote'),
            clickFn: () => getQuote("quote")
          }}
        />
      </div>
    </section>
  )
}
export default MoveSteps;

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import {MenuType, SubMenuType} from "./modules/enums";

import Home from "./pages/home/Home";
import Siju from "./pages/products/Siju";
import Career from "./pages/company/Career";
import AboutUs from "./pages/company/AboutUs";
import DriveHub from "./pages/products/DriveHub";
import ContactUs from "./pages/company/ContactUs";
import MoveByTruq from "./pages/products/MoveByTruq";
import ServiceTerms from "./pages/legals/ServiceTerms";
import PartnerTerms from "./pages/legals/PartnerTerms";
import PrivacyPolicy from "./pages/legals/PrivacyPolicy";
import GetStarted from "./components/fragment/GetStarted";
import UseScrollToTop from "./components/hooks/useScrollToTop";

import Error403 from "./pages/misc/Error403";
import Error404 from "./pages/misc/Error404";
import UnderConstruction from "./pages/misc/UnderContruction";


const App = () => {

  return (
    <Router>
      <UseScrollToTop/>
      <Toaster containerStyle={{zIndex: '99999999'}} position="top-right" reverseOrder={false}/>
      <Routes>
        {['/', MenuType.HOME].map(path => <Route path={path} key={path} element={<Home/>}/>)}
        {['about/us', SubMenuType.ABOUT_US].map(path => <Route path={path} element={<AboutUs/>}/>)}
        {['contact/us', SubMenuType.CONTACT_US].map(path => <Route path={path} element={<ContactUs/>}/>)}

        <Route path={MenuType.LEGAL}>
          <Route path={SubMenuType.PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
          <Route path={SubMenuType.TERMS_DRIVER_PATNER} element={<PartnerTerms/>}/>
          <Route index path={SubMenuType.TERMS_CONDITION} element={<ServiceTerms/>}/>
        </Route>
        <Route path={MenuType.PRODUCTS}>
          <Route path={SubMenuType.SIJU} element={<Siju/>}/>
          <Route path={SubMenuType.TRUQ_APP} element={<DriveHub/>}/>
          <Route index path={SubMenuType.TRUQ} element={<MoveByTruq/>}/>
        </Route>
        <Route path={MenuType.RESOURCES}>
        </Route>
        <Route path={MenuType.COMPANY}>
          <Route path={SubMenuType.ABOUT_US} element={<AboutUs/>}/>
          <Route path={SubMenuType.CAREER} element={<Career/>}/>
          <Route path={SubMenuType.CONTACT_US} element={<ContactUs/>}/>
        </Route>

        <Route path={MenuType.GET_STARTED} element={<GetStarted/>}/>
        <Route path={MenuType.CONSTRUCTION} element={<UnderConstruction/>}/>
        <Route path={MenuType.UNAUTHORISED} element={<Error403/>}/>
        <Route path="*" element={<Error404/>}/>
      </Routes>
    </Router>
  );
}
export default App;

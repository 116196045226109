import { Fragment } from "react";
import { Image, Row, Nav, Tab } from "react-bootstrap";
import { ITab } from "../../../modules/components";
import GetStartedButton from "../../../components/buttons/GetStartedButton";

type Props = {
  title: string;
  data: ITab[]
}

const SijuSteps = ({title, data}: Props) => (
  <Fragment>
    <section className="my-5 my-md-150 d-flex flex-column">
      <h3 className="text-darker w-1000 w-100 text-center align-self-center">
        {title}
      </h3>

      <Tab.Container defaultActiveKey="1">
        <Row className="mt-5">
          <div className="col-md-6 col-xl-5 mb-3 h-100">
            <Nav variant="underline" className="flex-column nav-underline">
              {data.map((tab) => (
                <Nav.Item key={tab.id}>
                  <Nav.Link eventKey={tab.id.toString()}>{tab.title}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
          <div className="col-md-6 col-xl-7 mb-3 h-100">
            <Tab.Content className="tab-content-container p-3">
              {data.map((tab) => (
                <Tab.Pane key={tab.id} eventKey={tab.id.toString()}>
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <Image src={tab.src} alt={tab.alt} className="img-fluid object-fit-cover curved-images"/>
                  </div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </div>
        </Row>
      </Tab.Container>
      <div className="m-4">
        <GetStartedButton/>
      </div>
    </section>
  </Fragment>
)

export default SijuSteps;

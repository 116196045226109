import { useNavigate } from "react-router-dom";
import { MenuType, SubMenuType } from "../../../modules/enums";
import DarkButton from "../../../components/buttons/DarkButton";
import GetStartedButton from "../../../components/buttons/GetStartedButton";

const Hero = () => {
  const navigate = useNavigate();

  return (
    <section>
      <div className="home-hero">
        <h1 className="mt-md-70">
          Supercharge Your Logistics <span className="text-orange">Hustle!</span>
        </h1>
        <p className="hero-description text-white">
          Log your trips and get paid faster, access financing for your operations, enjoy discounts on your everyday expenses and connect with people that need your vehicle.
        </p>
        <div className="d-flex gap-4 mb-5 mt-3">
          <GetStartedButton className="px-4 px-md-5" />
          <DarkButton
            btn={{
              text: "Learn More",
              btnClassName: 'btn-block px-4 px-md-5 border border-white',
              clickFn: () => navigate(`/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_APP}`)
            }}
          />
        </div>
      </div>
    </section>
  )
}
export default Hero;

import {Fragment} from "react";
import {Image} from "react-bootstrap";
import {NavLink} from "react-router-dom";

import {useTheme} from "../hooks/useTheme";
import DarkButton from "../buttons/DarkButton";
import truQLogo from "../../assets/images/pngs/truq-logo.png";
import {AppTheme, LinkType, MenuType} from "../../modules/enums";
import {FooterLinks, FooterPlatforms} from "../../utils/mapper/Mapper";
import truQLogoWhite from "../../assets/images/pngs/truq-logo-white.png";


const Footer = ({theme}: { theme?: AppTheme }) => {
  const appTheme = useTheme();
  const darkMode = appTheme.theme === AppTheme.DARK || theme === AppTheme.DARK;


  return (
    <footer className={darkMode ? "bg-dark" : ""}>
      <section className="info-container">
        <div className="footer-links">
          {FooterLinks.map((item, index) =>
            <div key={index} className={item.className}>
              <h6 className="text-uppercase mb-3">{item.title}</h6>
              {item.content.map((content, contentIndex) =>
                <Fragment key={contentIndex}>
                  {content.link_type === LinkType.IN_APP ?
                    <NavLink to={content.link} className="d-block mb-2">{content.text}</NavLink>
                    :
                    <a href={content.link} target="_blank" rel="noreferrer" className="d-block mb-2">{content.text}</a>
                  }
                </Fragment>
              )}
            </div>
          )}
        </div>
        <div className="d-flex flex-wrap row-gap-2 justify-content-between mb-5">
          <h6 className="my-2">truQ Platforms</h6>
          <div className="d-flex flex-wrap row-gap-3 column-gap-md-4 column-gap-3">
            {FooterPlatforms.map((item, index) =>
              <DarkButton key={index}
                btn={{
                  text: item.title,
                  btnClassName: `btn-block px-3 ${darkMode ? "" : "btn-lighter"}`,
                  clickFn: () => window.open(item.link)
                }}
              />
            )}
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row column-gap-5 row-gap-1 py-5 border-top border-dark-subtle">
          <NavLink to={`/${MenuType.HOME}`} className="mx-0">
            {darkMode ? <Image src={truQLogoWhite} alt="truQ Logo"/> : <Image src={truQLogo} alt="truQ Logo"/>}
          </NavLink>
          <small className="footer-note">
            9 Gbagada - Oworonshoki Expy, Araromi, Lagos 105102, Lagos
          </small>
        </div>
      </section>
    </footer>
  );
}

export default Footer;

import {IQuoteLoadState} from "../../../modules/components";
import StackComponent from "../../../components/fragment/Stack";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { AllYouNeed } from "../../../utils/mapper/Mapper";
import { ColumnOrder } from "../../../modules/enums";
import GetStartedButton from "../../../components/buttons/GetStartedButton";

type Props = {
  loadState: IQuoteLoadState;
  getQuote: (type: string) => void
}
const MoveExperience = ({ loadState, getQuote}: Props) => {

  return (
      <section className="mt-5 mt-md-70 pt-0 pt-md-5">
        <div className="text-center">
          <h3 className="text-darker mx-auto">We’ve Got All You Need</h3>
          <StackComponent steps={AllYouNeed} imgOrder={ColumnOrder.FIRST} enableScrollLock={false}/>
          
        </div>
        <div className="mt-lg-3 d-flex  justify-content-lg-center mt-0 ms-lg-200">
                    <GetStartedButton />
                    <PrimaryButton
                        btn={{
                            text: "Check Quote",
                            btnClassName: "px-3 ms-4 border border-primary",
                            outline: true,
                            loadState: (loadState.state && loadState.index === 'quote'),
                            clickFn: () => getQuote("quote")
                        }}
                    />
                </div>
      </section>
  )
}

export default MoveExperience

import { Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb} from "react-bootstrap";
import PageLayout from "../layouts/PageLayout";
import {IPolicy} from "../../modules/components";
import PolicyLinks from "../navigation/PolicyLinks";
import PoliciesOrderedList from "../../pages/legals/atom/PoliciesOrderedList";
import { PolicyLinkPlacement, LinkType, LEGALS, MenuType, SubMenuType } from "../../modules/enums";

type Props = {
  type: LEGALS,
  description: string | string[],
  policies: IPolicy[],
  footer?: { title: string, subTitle?: string}
}

const Policy = ({ type, description, policies, footer }: Props) => {
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const scrollToItem = (index: number) => {
    const item = itemRefs.current[index];

    if (item) {
      item.scrollIntoView({ behavior: 'smooth',block: "center"});
      const paddingTop = 20;
      item.scrollTop = item.offsetTop - paddingTop;
    }
  };

  return (
    <PageLayout>
      <section className="main-wrapper">
        <main className="main-content no-scrollbar" >
          <Breadcrumb className="mx-3 mt-3 mb-5 mx-lg-5">
            <Breadcrumb.Item active={(type && type === LEGALS.PRIVACY_POLICY)}>
              <Link to={`/${MenuType.LEGAL}/${SubMenuType.PRIVACY_POLICY}`}>
                Privacy Policy
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active={(type && type === LEGALS.TERMS_CONDITION)}>
              <Link to={`/${MenuType.LEGAL}/${SubMenuType.TERMS_CONDITION}`}>
                Terms & Condition
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active={(type && type === LEGALS.TERMS_DRIVER_PATNER)}>
              <Link to={`/${MenuType.LEGAL}/${SubMenuType.TERMS_DRIVER_PATNER}`}>
                Partners & Drivers Terms of Service
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="text-medium m-3 m-lg-5">
            <p className="text-break text-justify">{description}</p>
            <div>
              {policies.map((policy, policyIndex) =>
                <div id={`${policyIndex}`} className="mt-5" key={policyIndex} ref={(el) => (itemRefs.current[policyIndex] = el)}>
                  <h5 className="text-dark">
                    {policy.title}{" "}
                    {(policy.link && policy.link.placement === PolicyLinkPlacement.TITLE) && <PolicyLinks link={policy.link}/>}
                  </h5>
                  {policy.subTitle && <h6 className="mt-3">
                    {policy.subTitle}{" "}
                    {(policy.link && policy.link.placement === PolicyLinkPlacement.SUBTITLE) &&  <PolicyLinks link={policy.link}/>}
                  </h6>
                  }
                  {policy.description &&
                    <Fragment>
                      {(policy.description && typeof policy.description === "string")
                        ? <p className="mt-2">
                          {policy.description}{" "}
                          {(policy.link && policy.link.placement === PolicyLinkPlacement.DESCRIPTION) && <PolicyLinks link={policy.link}/>}
                        </p>
                        : <Fragment>
                          {Array.isArray(policy.description) && policy.description.map((policyDescription, descriptionIndex) =>
                            <p key={descriptionIndex} className="mt-2">
                              {policyDescription}
                              {policy.description}{" "}{(policy.link && policy.link.placement === PolicyLinkPlacement.DESCRIPTION) &&
                                <a href={policy.link.type === LinkType.EMAIL ? `mailto${policy.link.href}` : policy.link.href}>
                                  {policy.link.href}
                                </a>
                              }
                            </p>
                          )}
                        </Fragment>
                      }
                    </Fragment>
                  }
                  {policy.extras && <PoliciesOrderedList policyExtras={policy.extras}/>}

                  <p className="mt-3">
                    {policy?.footer}{" "}
                    {(policy.link && policy.link.placement === PolicyLinkPlacement.FOOTER) && <PolicyLinks link={policy.link}/>}
                  </p>
                </div>
              )}
              {(footer && footer.title) && <h5 className="mt-5">{footer.title}</h5>}
              {(footer && footer.subTitle) && <h6 className="mt-3"> {footer.subTitle}</h6>}
            </div>
          </div>
        </main>
        <aside className="side-content no-scrollbar">
          <h5 className="mb-2">On this page</h5>
          {policies.map((policy, policyIndex) => <p key={policyIndex} onClick={() => scrollToItem(policyIndex)}
            className="my-3 text-primary cursor-pointer " style={{ fontWeight: "550" }}>
            {policy.title}
          </p>
          )}
        </aside>
      </section>
    </PageLayout>
  );
}

export default Policy;

import {SourceType} from "../../../modules/enums";
import {CoreBeliefsData} from "../../../utils/mapper/Mapper";
import blueArchSvg from "../../../assets/images/svgs/blue-arch.svg";
import CircleThumbnailWithIcons from "../../../components/fragment/CircleThumbnails";
import {Image} from "react-bootstrap";

const CoreBeliefs = () => (
  <section className="mx-3 px-md-5 mt-5 mt-md-100 mt-xxl-180">
    <h3 className="text-center align-self-center mt-0">truQ is built on 3 <span
      className="text-orange">Core Beliefs</span></h3>
    <Image alt="lazy" src={blueArchSvg} className="blue-arch mb-md-4"/>
    <CircleThumbnailWithIcons companyInfo={CoreBeliefsData} source={SourceType.ABOUT_US}/>
  </section>
)

export default CoreBeliefs;

export const toTitleCase = (str: string | undefined) => {
	if (!str || str.length === 0) return "";
	str = (/[_&\\/#, +()$~%.'":*?<>{}-]/g).test(str) ? str.replaceAll(/[_&\\/#, +()$~%.'":*?<>{}-]/g, ' ') : str;
	return str.trim().split(' ').map(word => (
		word[0] ? word[0].toUpperCase() + word.substring(1).toLowerCase() : word + word.substring(1).toLowerCase()
	)).join(' ')
};

export const formatWebsite = (website: string) => {
	if (/(http(s?)):\/\//i.test(website)) return website;
	else return `https://${website}`;
}
export const queryErrorHandler = (type: string, response?: { data?: any; error?: any; }) => {
	if (!response) return `You cannot ${type.toLowerCase()} at this time, Please try again later.`;
	let errMessage = "";
	if (response && response.error && response.error.data && response.error.data.message) errMessage = response.error.data.message;
	else if (response && response.data && response.data.message) errMessage = response.data.message;
	else errMessage = `You cannot ${type.toLowerCase()} at this time, Please try again later.`;
	return errMessage;
};
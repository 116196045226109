import {useState} from "react";
import {Col, Image, Row} from "react-bootstrap";
import Tabs from "../../../components/fragment/Tabs";
import {ProductsTab} from "../../../utils/mapper/Mapper";
import CoinsSVG from "../../../assets/images/svgs/coins.svg";
import SuccessSVG from "../../../assets/images/svgs/Successful.svg";


const Products = () => {
  const [activeTabKey, setActiveTabKey] = useState(ProductsTab[0].key);

  const getImageForActiveTab = (key: string) => {
    switch (key) {
      case "move_by_truq":
        return "https://storage.googleapis.com/public_storage_truq/website/home_webappproduct_wgajsdsdiks.jpg";
      case "siju_by_truq":
        return "https://storage.googleapis.com/public_storage_truq/website/product_siju_agshgjdue.png";
      default:
        return "https://storage.googleapis.com/public_storage_truq/website/home_driverappproduct_stianmcu.png";
    }
  };

  return (
    <Row className="my-5 my-md-150 tab-container">
      <Col md={6} className="h-auto mt-3 mt-md-5">
        <h3 className="text-center text-md-start">Our Products</h3>
        <p className="text-medium mt-2 mb-3 text-center text-md-start">
          Explore our ecosystem of solutions, powering smarter, faster, and more efficient logistics operations
          for everyone.
        </p>
        <Tabs data={ProductsTab} setActiveTabKey={setActiveTabKey}/>
      </Col>
      <Col md={6} className={`h-auto ${activeTabKey} my-4 curved-images`}>
        { activeTabKey === "truq_app" &&
					<>
						<div className="success-message">
							<div className="px-2">
								<p className="success-header">Your cash has been Transferred</p>
								<p className="success-text">Successfully</p>
							</div>
							<Image className="mx-2" alt="successful" src={SuccessSVG} width={55} height={55}/>
						</div>
						<div className="points-notification">
							<div className="px-2">
								<p className="points-header">You have earned</p>
								<p className="points-text">200 Points</p>
							</div>
							<Image alt="coins" src={CoinsSVG} width={30} height={35} className="ms-4 me-2"/>
						</div>
					</>
        }
        <Image src={getImageForActiveTab(activeTabKey)} alt="Product Image" />
      </Col>
    </Row>
  )
}

export default Products;

import {Container, Image} from "react-bootstrap";
import CrownSVG from "../../../../assets/images/svgs/crown.svg";
import StarSVG from "../../../../assets/images/svgs/star.svg";
import WhiteStarSVG from "../../../../assets/images/svgs/white-star.svg";

const CareerHero = () => (
  <section className="grid-white mt-md-100 my-5">
    <Container className="d-flex flex-column ">
      <div className="d-flex align-self-center pt-5 pt-md-4 px-2 px-md-0">
        <div>
          <h2 className="mt-3 text-center text-md-start">
            <Image
              alt="CROWN"
              src={CrownSVG}
              className="align-self-start crown-position"
            />We are truQstars! 🚀💫</h2>
          <div className="d-flex justify-content-center align-items-center mt-2 w-500">
            <p className="text-center">
              We are obsessed with doing hard things as long as it affects the progress of Africa!
            </p>
          </div>
        </div>
      </div>
      <Image
        alt="STAR"
        src={StarSVG}
        className="align-self-start mt-5 mt-md-0"
      />
      <div className="mx-auto mt-md-3 mb-md-80" style={{maxWidth: "620px"}}>
        <Image
          alt="HERO"
          src="https://storage.googleapis.com/public_storage_truq/website/career_hero_tuiosdfkhs.jpg"
          className="rounded-4 img-fluid object-fit-cover"
        />
      </div>
      <Image
        alt="STAR"
        src={WhiteStarSVG}
        className="align-self-end my-md-4"
        width={24}
      />
    </Container>
  </section>

)
export default CareerHero

import { Container, Image } from "react-bootstrap";
import { IQuoteLoadState } from "../../../../modules/components";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import GetStartedButton from "../../../../components/buttons/GetStartedButton";
import TextLoop from "../../../../components/fragment/TextLoop";

type Props = {
  loadState: IQuoteLoadState;
  getQuote: (type: string) => void
}
const TruqMoveHero = ({ loadState, getQuote }: Props) => (
  <section className="moveByTruq-hero">
    <Container className="d-flex flex-column">
      <div className="text-center mt-2">
        <h1 className="w-720 w-100 mx-auto">
          Moving Homes Should Never Be Stressful for
          <br />
          <TextLoop data={["You", "Your Customers", "Your Community Members", " Your Users"]} className="mx-2 text-orange text-center" />
        </h1>
        <p className="hero-description">
          Sign up or plug into MovebytruQ to access seamless logistics for yourself or for your customers.
        </p>
      </div>

      <div className="d-flex gap-4 my-3 justify-content-center">
        <GetStartedButton />
        <PrimaryButton
          btn={{
            text: "Check Quote",
            btnClassName: "px-4 border border-primary",
            outline: true,
            loadState: (loadState.state && loadState.index === 'quote'),
            clickFn: () => getQuote("quote")
          }}
        />
      </div>

      <div className="hero_container d-flex justify-content-center align-self-center my-5">
        <Image
          alt="HERO"
          src="https://storage.googleapis.com/public_storage_truq/website/move_hero_qkjfhskfksf.jpg"
          className="img-fluid object-fit-cover curved-images bg-primary-blue"
        />
      </div>
    </Container>
  </section>
)

export default TruqMoveHero

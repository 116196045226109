import AppStore from "./AppStore";
import PlayStore from "./PlayStore";
import GetStartedButton from "./GetStartedButton";

type Props = {
  className?: string;
  handleClick: () => void
}
const DriveHubActions = ({className, handleClick}: Props) => (
    <div className={`d-flex flex-lg-row flex-wrap align-items-center justify-content-center gap-3 gap-md-4 ${className ?? ''}`}>
    <AppStore/>
    <GetStartedButton/>
    <PlayStore/>
  </div>
)

export default DriveHubActions;

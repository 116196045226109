import { IQuoteLoadState } from "../../../modules/components";
import StackComponent from "../../../components/fragment/Stack";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { SeamlessnessRedefined } from "../../../utils/mapper/Mapper";
import { ColumnOrder } from "../../../modules/enums";
import GetStartedButton from "../../../components/buttons/GetStartedButton";

type Props = {
    loadState: IQuoteLoadState;
    getQuote: (type: string) => void
}
const MoveSeamlessly = ({ loadState, getQuote }: Props) => {

    return (
        <section className="mt-5 mt-md-70 pt-0 pt-md-5">
            <div className="text-center">
                <h4 className="hero-description mt-0 mt-md-3 mb-0 text-primary">
                    Seamlessness redefined
                </h4>
                <h3 className="text-darker mx-auto mt-0 w-600">For your moving and home maintenance needs.</h3>
                <StackComponent steps={SeamlessnessRedefined} imgOrder={ColumnOrder.SECOND} enableScrollLock={false}/>
                <div className="mt-lg-3 mt-0 d-flex justify-content-start">
                    <GetStartedButton />
                    <PrimaryButton
                        btn={{
                            text: "Check Quote",
                            btnClassName: "px-3 ms-4 border border-primary",
                            outline: true,
                            loadState: (loadState.state && loadState.index === 'quote'),
                            clickFn: () => getQuote("quote")
                        }}
                    />
                </div>
            </div>
        </section>
    )
}

export default MoveSeamlessly

import Slider from "react-slick";
import {Logos} from "../../utils/mapper/Mapper";
import {Image} from "react-bootstrap";

type Props = {
  title?: string;
  description?: string;
};

const Brands = ({title, description }: Props) => {

  const ReactSlickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    cssease: "linear",
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
        }
      },
    ]
  }

  return (
    <div className="bg-light-lilac pb-5">
      <h6 className="text-primary-darker text-center mt-5 mb-2 mb-md-1">{title ?? `Customers & Partners`}</h6>
      <p className={`font-size-16 text-medium text-center`}>{description ?? `We are supported by the right network of customers, partners and service providers`}</p>
      <div className="mt-5">
        <Slider {...ReactSlickSettings} >
          {Logos.map((item, index) => <Image key={index} alt={item.alt} src={item.src}
                                             className="img-fluid object-fit-contain h-45"/>
          )}
        </Slider>
      </div>
    </div>

  )
}

export default Brands;

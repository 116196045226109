import {IHeader, IProduct, ITab} from "../../modules/components";
// import Faqs from "../../assets/images/svgs/faqs.svg";
// import Demo from "../../assets/images/svgs/demo.svg";
import Career from "../../assets/images/svgs/career.svg";
import AboutUs from "../../assets/images/svgs/about-us.svg";
import ApiDocs from "../../assets/images/svgs/api-docs.svg";
import cardSVG from "../../assets/images/svgs/card-pos.svg";
import paymentSVG from "../../assets/images/svgs/payment.svg";
import discountSVG from "../../assets/images/svgs/discount.svg";
import moneysSVG from "../../assets/images/svgs/moneys.svg";
import peopleSVG from "../../assets/images/svgs/people.svg";
import DriverHub from "../../assets/images/svgs/drivehub.svg";
import feature4 from "../../assets/images/svgs/logo/vendease.svg";
import ContactUs from "../../assets/images/svgs/contact-us.svg";
import UserGuide from "../../assets/images/svgs/user-guide.svg";
import feature3 from "../../assets/images/svgs/logo/yalo.svg";
import feature8 from "../../assets/images/svgs/logo/seedfi.svg";
import feature1 from "../../assets/images/svgs/logo/payaza.svg";
import feature2 from "../../assets/images/svgs/logo/google.svg";
import SijuByTruq from "../../assets/images/svgs/siju-by-truQ.svg";
import MoveByTruq from "../../assets/images/svgs/move-by-truQ.svg";
import feature10 from "../../assets/images/svgs/logo/mycoverai.svg";
import feature6 from "../../assets/images/svgs/logo/paystack.svg";
import feature5 from "../../assets/images/svgs/logo/ulospaces.svg";
import feature11 from "../../assets/images/svgs/logo/driveme.svg";
// import PartnershipBase from "../../assets/images/svgs/partnership.svg";
import solution1 from "../../assets/images/pngs/truQPay/solution1.png";
import solution2 from "../../assets/images/pngs/truQPay/solution2.png";
import solution3 from "../../assets/images/pngs/truQPay/solution3.png";
import feature9 from "../../assets/images/svgs/logo/lenco.svg";
import feature7 from "../../assets/images/svgs/logo/providus.svg";
import {LinkType, MenuType, NavItemType, SubMenuType, ColumnOrder} from "../../modules/enums";


// HEADER DATA
export const NavItems = [
  {
    name: "Products",
    type: NavItemType.PRODUCTS
  },
  {
    name: "Company",
    type: NavItemType.COMPANY
  },
  {
    name: "Resources",
    type: NavItemType.RESOURCES
  }
]

export const ProductsData: IHeader[] = [
  {
    alt: "truQ",
    src: DriverHub,
    title: "truQ",
    subtitle: "For drivers and vehicle owners",
    link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_APP}`,
    link_type: LinkType.IN_APP
  },
  {
    alt: "Move_By_Truq",
    src: MoveByTruq,
    title: "Move by truQ",
    subtitle: "For house moves and partners",
    link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ}`,
    link_type: LinkType.IN_APP
  },
  // {
  //   imgAlt: "Truq_Pay",
  //   imgSrc: TruqPay,
  //   title: "truQ Pay",
  //   subtitle: "Lorem ipsum dolor sit amet",
  //   link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_PAY}`,
  //   link_type: LinkType.IN_APP
  // },
  {
    alt: "Siju_By_truQ",
    src: SijuByTruq,
    title: "Siju by truQ",
    subtitle: "For businesses",
    link: `/${MenuType.PRODUCTS}/${SubMenuType.SIJU}`,
    link_type: LinkType.IN_APP
  }
]

export const CompanyData: IHeader[] = [
  {
    alt: "About_Us",
    src: AboutUs,
    title: "About Us",
    subtitle: "Who we are and what we do",
    link: `/${MenuType.COMPANY}/${SubMenuType.ABOUT_US}`,
    link_type: LinkType.IN_APP
  },
  {
    alt: "Contact_Us",
    src: ContactUs,
    title: "Contact Us",
    subtitle: "Get in touch with us",
    link: `/${MenuType.COMPANY}/${SubMenuType.CONTACT_US}`,
    link_type: LinkType.IN_APP
  },
  {
    alt: "Career",
    src: Career,
    title: "Career",
    subtitle: "Become a truQstar today!",
    link: `/${MenuType.COMPANY}/${SubMenuType.CAREER}`,
    link_type: LinkType.IN_APP
  },
]

export const ResourcesData: IHeader[] = [
  {
    alt: "user_guide",
    src: UserGuide,
    title: "Users Guide",
    subtitle: "Learn how truQ works",
    link: "https://docs.mytruq.com/",
    link_type: LinkType.TARGET
  },
  {
    alt: "Api_Docs",
    src: ApiDocs,
    title: "API Docs",
    subtitle: "Access our API guides and documentation",
    link: "https://truq-services.gitbook.io",
    link_type: LinkType.TARGET
  },
  // {
  //   alt: "Demo_Video",
  //   src: Demo,
  //   title: "Demo Video",
  //   subtitle: "Explore our support resources",
  //   link: `/${MenuType.CONSTRUCTION}`,
  //   link_type: LinkType.IN_APP
  // },
  // {
  //   alt: "Partnership_Base",
  //   src: PartnershipBase,
  //   title: "Partnership Base",
  //   subtitle: "Collaborate with us for growth",
  //   link: `/${MenuType.CONSTRUCTION}`,
  //   link_type: LinkType.IN_APP
  // },
  // {
  //   alt: "Blogs",
  //   src: SijuByTruq,
  //   title: "Blogs",
  //   subtitle: "Keep up with Insights & updates with truQ",
  //   link: `/${MenuType.CONSTRUCTION}/`,
  //   link_type: LinkType.IN_APP
  // },
  // {
  //   alt: "Faqs",
  //   src: Faqs,
  //   title: "FAQs",
  //   subtitle: "Find answers to common questions",
  //   link: `/${MenuType.CONSTRUCTION}`,
  //   link_type: LinkType.IN_APP
  // },
]

// FOOTER DATA
export const FooterLinks = [
  {
    title: "Product",
    className: "order-1",
    content: [
      {
        text: "truQ",
        link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_APP}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Move by truQ",
        link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Siju by truQ",
        link: `/${MenuType.PRODUCTS}/${SubMenuType.SIJU}`,
        link_type: LinkType.IN_APP
      },
      // {
      //   text: "truQ Pay",
      //   link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_PAY}`,
      //   link_type: LinkType.IN_APP
      // },
    ]
  },
  {
    title: "Company",
    className: "order-2 order-md-3",
    content: [
      {
        text: "About Us",
        link: `/${MenuType.COMPANY}/${SubMenuType.ABOUT_US}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Contact Us",
        link: `/${MenuType.COMPANY}/${SubMenuType.CONTACT_US}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Career",
        link: `/${MenuType.COMPANY}/${SubMenuType.CAREER}`,
        link_type: LinkType.IN_APP
      },
    ]
  },
  {
    title: "Legal",
    className: "order-5 order-md-4",
    content: [
      {
        text: "Terms and Condition",
        link: `/${MenuType.LEGAL}/${SubMenuType.TERMS_CONDITION}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Privacy Policy",
        link: `/${MenuType.LEGAL}/${SubMenuType.PRIVACY_POLICY}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Terms for Drivers and Partners",
        link: `/${MenuType.LEGAL}/${SubMenuType.TERMS_DRIVER_PATNER}`,
        link_type: LinkType.IN_APP
      },
    ]
  },
  {
    title: "Resources",
    className: "order-3 order-md-2",
    content: [
      {
        text: "Users Guide",
        link: "https://truq-services.gitbook.io",
        link_type: LinkType.TARGET
      },
      {
        text: "API Docs",
        link: "https://docs.mytruq.com/",
        link_type: LinkType.TARGET
      },
      // {
      //   text: "FAQs",
      //   link: `/${MenuType.CONSTRUCTION}`,
      //   link_type: LinkType.IN_APP
      // },
      // {
      //   text: "Blog",
      //   link: `/${MenuType.CONSTRUCTION}`,
      //   link_type: LinkType.IN_APP
      // },
      // {
      //   text: "Demo Videos",
      //   link: `/${MenuType.CONSTRUCTION}`,
      //   link_type: LinkType.IN_APP
      // },
      // {
      //   text: "Partnerships Base",
      //   link: `/${MenuType.CONSTRUCTION}`,
      //   link_type: LinkType.IN_APP
      // },
    ]
  },
  {
    title: "Socials",
    className: "order-4 order-md-5",
    content: [
      {
        text: "LinkedIn",
        link: "https://www.linkedin.com/company/truq-logistics/",
        link_type: LinkType.TARGET
      },
      {
        text: "Facebook",
        link: "https://web.facebook.com/Mytruq/",
        link_type: LinkType.TARGET
      },
      {
        text: "Instagram",
        link: "https://www.instagram.com/mytruq/",
        link_type: LinkType.TARGET
      },
      {
        text: "Twitter",
        link: "https://twitter.com/MytruQ",
        link_type: LinkType.TARGET
      }
    ]
  },
];

export const FooterPlatforms = [
  {
    title: "Move by truQ",
    link: "https://app.mytruq.com/auth/login",

  },
  {
    title: "Siju by truQ",
    link: "https://app.siju.it/auth/login",

  },
  {
    title: "Download iOS app",
    link: "https://apps.apple.com/ng/app/truq-driver/id1635132424",
  },
  {
    title: "Download Android app",
    link: "https://play.google.com/store/apps/details?id=com.mytruq.driver",
  }
]

// COMPONENTS DATA

export const AllYouNeed = [
  {
    data: 10,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/move_experience_1_xsgdhwf.jpg",
    title: "Vehicles to help you move",
    description: "Minivans, vans or trucks, different sizes of vehicles for all your moving needs.",
  },
  {
    data: 20,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/move_experience_1_sdjfkldkl.jpg",
    title: "Moving and packaging for a compact move",
    description: "Boxes, shrink wraps, bubble wraps, movers, everything you need for a compact move"
  },
  {
    data: 30,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/move_experience_1_sdjfkldkl.jpg",
    title: "Access to domestic artisans",
    description: "Ancillary services partners for your home and home maintenance needs"
  },
];

export const CompanyInformation = [
  {
    alt: "presentation-chart",
    src: paymentSVG,
    header: "Get paid faster",
    text: "Log your trips, access quick payment and build a verifiable earring history",
    textClassName: "company-info-text",
    iconStyle: "bg-orange",
  },
  {
    alt: "card",
    src: cardSVG,
    header: "Access financial support for your business",
    text: "Get paid faster and access financing solutions for your expenses.",
    textClassName: "company-info-text",
    iconStyle: "bg-orange",
  },
  {
    alt: "card",
    src: discountSVG,
    header: "Get discounts on your expenses",
    text: "Save money on your expenses with juicy discounts from our service partners.",
    textClassName: "company-info-text",
    iconStyle: "bg-orange",
  },
  {
    alt: "moneys",
    src: moneysSVG,
    header: "Earn more money",
    text: "Boost your earnings and make more money by accepting trips from our business partners.",
    textClassName: "company-info-text",
    iconStyle: "bg-orange",
  },
  {
    alt: "people",
    src: peopleSVG,
    header: "Get empowered",
    text: "Connect with other operators, assess trainings, tools, hangouts, and do business with community members.",
    textClassName: "company-info-text",
    iconStyle: "bg-orange",
  }
];

export const CoreBeliefsData = [
  {
    alt: "",
    src: "",
    number: "1",
    text: "Inefficient logistics processes would always be difficult and expensive to manage or scale for any business",
    textClassName: "beliefs-text",
    iconStyle: "bg-light-orange text-orange",
  },
  {
    alt: "",
    src: "",
    number: "2",
    text: "Logistics success goes beyond just process optimization, it also heavily depends on the capability of the vehicle to be efficient",
    textClassName: "beliefs-text",
    iconStyle: "bg-orange",
  },
  {
    alt: "",
    src: "",
    number: "3",
    text: "1 and 2 above are best executed in a connected system with minimal friction and fragmentation.",
    textClassName: "beliefs-text",
    iconStyle: "bg-light-orange text-primary",
  },
];

export const DriverEmpowerment = [
  {
    data: 10,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/driver_empowerment_1_sdhfkjs.jpg",
    title: "Digitize all your hustle",
    description: "Log your trips,buy insurance per trip, receive payments, share real-time status update link, record your sales, track your expenses,and Issue invoices."
  },
  {
    data: 20,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/driver_empowerment_1_sdhfkjs.jpg",
    title: "Access financial support for your business:",
    description: "Access financing for all your operational expenses, fueling, repairs, spare parts etc. without the hassle of paying at once."
  },
  {
    data: 30,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/driver_empowerment_2_fbsdkjsd.jpg",
    title: "Get discounts on your expenses",
    description: "Enjoy discounts at fuel stations, mechanic workshops, spare part stores etc when you patronize any of our partner merchants."
  },
  {
    data: 40,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/driver_empowerment_2_fbsdkjsd.jpg",
    title: "Earn more money",
    description: "Reduce your vehicle idle periods, get real time connections with people and businesses who need your service to earn more money."
  },
  {
    data: 50,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/driver_empowerment_2_fbsdkjsd.jpg",
    title: "Connect with your people",
    description: "Connect with other operators, access training, tools, hangouts, and do business with community members."
  },
];

export const DriveHubSteps = [
  {
    number: "1",
    title: "Download the truQ app",
    description: `Download the truQ app from play store or app store`,
    src: "https://storage.googleapis.com/public_storage_truq/website/driverapp_getstarted_1_sdfngdjk.png",
    alt: "download_app",
  },
  {
    number: "2",
    title: "Sign Up",
    description: "Provide simple personal details to signup",
    src: "https://storage.googleapis.com/public_storage_truq/website/driverapp_getstarted_2_sfmndf.png",
    alt: "sign_up",
  },
  {
    number: "3",
    title: "Upload required documents",
    description: `Upload all driver and vehicle documents as required`,
    src: "https://storage.googleapis.com/public_storage_truq/website/driverapp_getstarted_3_sfjsdjdss.png",
    alt: "upload_documents",
  },
  {
    number: "4",
    title: "Get verified and approved",
    description: `Take less than 24 hours to verify all your documentation`,
    src: "https://storage.googleapis.com/public_storage_truq/website/driverapp_getstarted_4_sdhndfkl.png",
    alt: "get_verified",
  },
  {
    number: "5",
    title: "Welcome to the next level of your logistics operations",
    src: "https://storage.googleapis.com/public_storage_truq/website/driverapp_getstarted_5_dhsfkf.png",
    alt: "welcome"
  },
];

export const GetStartedTabs = [
  {
    text: "Move", 
    id: "move",
    value: 'MOVE'
  },
  {
    text: "Partner", 
    id: 'partner',
    value: "PARTNER"
  },
  {
    text: "Siju", 
    id: 'siju',
    value: "SIJU"
  },
  {
    text: "truQ", 
    id: 'truQ',
    value: "TRUQ"
  },
];

export const HomeTestimonies = [
  {
    content: "I referred my sister's husband and friends on the driver app and i got paid for that. I'm still looking for more drivers.",
    image: null,
    author: "Adaeze Ofor",
    medium: "",
    imageBg: "bg-info"
  },
  {
    content: "Had a very good experience, the move was smooth and the people went above and beyond to make sure everything was intact.",
    image: null,
    author: "Abdussamad Yisau",
    medium: "",
    imageBg: "bg-success"
  },
  {
    content: "Its Amazing that truQ could pull this off! Super impressed, no other solution for the market like this. Well Done guys.",
    image: null,
    author: "Cynthia Okeke",
    medium: "",
    imageBg: "bg-secondary"
  }
]

export const Logos = [
  {src: feature1, alt: 'Payaza'},
  {src: feature2, alt: 'Google'},
  {src: feature3, alt: 'Yalo'},
  {src: feature4, alt: 'Vfd'},
  {src: feature5, alt: 'Ulospaces'},
  {src: feature6, alt: 'Paystack'},
  {src: feature7, alt: 'ProvidusBank'},
  {src: feature8, alt: 'SeedFi'},
  {src: feature9, alt: 'Lenco'},
  {src: feature10, alt: 'MyCover.ai'},
  {src: feature11, alt: 'DriveMe'},
];

export const MoveTab: ITab[] = [
  {
    id: 1,
    title: "Book and pay for your move or service",
    src: "https://storage.googleapis.com/public_storage_truq/website/move_steps_1_sfbdfks.png",
    alt: 'book-and-pay'
  },
  {
    id: 2,
    title: "Connect instantly with the closest driver or service provider",
    src: "https://storage.googleapis.com/public_storage_truq/website/move_steps_1_sfbdfks.png",
    alt: 'connect-instantly'
  },
  {
    id: 3,
    title: "Receive real-time updates on your move and service",
    src: "https://storage.googleapis.com/public_storage_truq/website/move_steps_1_sfbdfks.png",
    alt: 'receive-instantly'
  },
  {
    id: 4,
    title: "Driver or service provider arrives promptly as scheduled",
    src: "https://storage.googleapis.com/public_storage_truq/website/move_steps_1_sfbdfks.png",
    alt: 'prompt-arrival'
  },
  {
    id: 5,
    title: "Driver or service provider gets paid upon delivery or service completion",
    src: "https://storage.googleapis.com/public_storage_truq/website/move_steps_1_sfbdfks.png",
    alt: 'service-completion'
  },
];

export const LogisticsOperations = [
  {
    data: 10,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/siju_empowerment_1_gsfjkjets.jpg",
    title: "Optimized delivery planning and aggregation",
    description: "Experience seamless delivery aggregation that fulfills your deliveries faster and saves you hours of manual work daily",
  },
  {
    data: 20,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/siju_empowerment_1_gsfjkjets.jpg",
    title: "Guaranteed Access to Vehicles",
    description: "Assigned and on-demand connection to different sizes of vehicles alreay running on truQ."
  },
  {
    data: 30,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/siju_empowerment_2_ljsudhsfhs.jpg",
    title: "Real-time oversight",
    description: "Enjoy real-time delivery coordination, status updates and reconciliation across all your deliveries and stops."
  },
  {
    data: 40,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/siju_empowerment_2_ljsudhsfhs.jpg",
    title: "Zero pricing surprises",
    description: "Pre-define pricing configuration and logistics cost management helping you achieve your logistics cost benchmarks."
  },
  {
    data: 50,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/siju_empowerment_2_ljsudhsfhs.jpg",
    title: "Team access and multi-level permissions",
    description: "Set permission across your units and stakeholders as you like, giving everyone the required access to play their part."
  },
];

export const MoveTestimonies = [
  {
    content: "This was my first time using a moving service and Truq came through pretty amazing and smooth service. Customer service was superb. Great job and service @Morenike really amazing 👏 👌 👍.",
    image: null,
    author: "NWABUAKU OKAFOR",
    medium: "Google Reviews",
    imageBg: "bg-info"
  },
  {
    content: "I'll use truQ service over and over again if I have to. I was worried at first, but they handled the whole process with ease. Customer relations from the drivers to the customer care services are top-notch.",
    image: null,
    author: "Mide isaac",
    medium: "Google Reviews",
    imageBg: "bg-success"
  },
  {
    content: "I enjoyed the customer service of truQ. They were very helpful and patient. It felt like I was talking to a friend. The driver was also very polite. Kudos to truQ!.",
    image: null,
    author: "Omo Imade",
    medium: "Google Reviews",
    imageBg: "bg-secondary"
  }
];

export const MovingExperienceData = [
  {
    border: "bg-primary",
    title: "Move at your convenience",
    description: "Moving now or later?, it's your call—your move, your schedule. Schedule your move at your discretion with guaranteed service."
  },
  {
    border: "bg-warning",
    title: "Seamless Connection",
    description: "Get connected with the closest verified and trusted driver on truQ with real-time status updates on your trip."
  },
  {
    border: "bg-orange",
    title: "With Peace of mind and top-Notch Care",
    description: "Secure your belongings with boxes, shrink wraps, and bubble wraps for utmost safety with an added layer of comprehensive insurance on your items from pickup to drop off."
  },
  {
    border: "bg-primary",
    title: "No Heavy Lifting",
    description: "Request loaders to handle all your heavy lifting needs with ease. Leave the hard work to our loaders—they'll handle it all"
  },
  {
    border: "bg-warning",
    title: "Post Move Services",
    description: "Beyond just moving, leverage our wide range of domestic service partners and partnership discounts to access cleaners, carpenters, electricians, plumbers etc to save you time and help you enjoy your new space"
  },
];

export const ProductsTab: IProduct[] = [
  {
    title: "truQ",
    key: "truq_app",
    description: `For ambitious drivers, providing them everything they need to be efficient for themselves and the market they service.`,
    details: [
      {text: "Log all your trips and get paid faster"},
      {text: "Build earning history and access financing"},
      {text: "Enjoy discounts on operational expenses"},
      {text: "Access more trips to earn more money"},
    ],
    link: {
      text: "Learn More",
      href: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_APP}`,
      type: LinkType.IN_APP,
    }
  },
  {
    title: "Move by truQ",
    key: "move_by_truq",
    description: `Logistics as a service infrastructure, powering ambitious moves for everyone`,
    details: [
      {text: "Connect with Multiple sizes of vehicles on truQ"},
      {text: "Enjoy on-demand maximum insurance on all trips"},
      {text: "Access integrated movers and ancillary service providers"},
      {text: "Offer logistics services to your customers & community members"},
    ],
    link: {
      text: "Learn More",
      href: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ}`,
      type: LinkType.IN_APP,
    }
  },
  {
    title: "Siju by truQ",
    key: "siju_by_truq",
    description: `For success-hungry businesses, helping you unlock the most efficient 3PL operations that powering you into your revenue milestones.`,
    details: [
      {text: "Optimized delivery planning and aggregation"},
      {text: "Access different sizes of vehicles on truQ"},
      {text: "Warehouse automation and inventory management"},
      {text: "Real-time delivery coordination, status updates and reconciliation"},
    ],
    link: {
      text: "Learn More",
      href: `/${MenuType.PRODUCTS}/${SubMenuType.SIJU}`,
      type: LinkType.IN_APP,
    }
  },
];

export const SeamlessnessRedefined = [
  {
    data: 10,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/move_seamlessly_1_ertusbskf.jpg",
    title: "At your convenience",
    description: "Make your move happen or get an artisan on your preferred schedule with guaranteed service.",
  },
  {
    data: 20,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/move_seamlessly_1_fdjdfsf.jpg",
    title: "Verified & Trusted",
    description: "Get connected with the closest verified and trusted partners on truQ with real-time status updates on your booking."
  },
  {
    data: 30,
    imageSrc: "https://storage.googleapis.com/public_storage_truq/website/move_seamlessly_1_fdjdfsf.jpg",
    title: "With Peace of mind and top-Notch Care",
    description: "Secure your belongings with boxes, shrink wraps, and bubble wraps for utmost safety with an added layer of comprehensive insurance on your items from pickup to drop off."
  },
];

export const SijuTab: ITab[] = [
  {
    id: 1,
    title: "Activate tech-enabled logistics operations end-to-end",
    src: "https://storage.googleapis.com/public_storage_truq/website/siju_steps_1_hkubskdl.jpg",
    alt: 'tech-enabled-logistics'
  },
  {
    id: 2,
    title: "Save costs with aggregated trips and flexible pricing",
    src: "https://storage.googleapis.com/public_storage_truq/website/siju_steps_2_vgshsjf.jpg",
    alt: 'save-cost'
  },
  {
    id: 3,
    title: "Gain real-time oversight and visibility into your deliveries",
    src: "https://storage.googleapis.com/public_storage_truq/website/siju_steps_3_adoprhsda.jpg",
    alt: 'real-time-oversight'
  },
  {
    id: 4,
    title: "Access a range of vehicle sizes for your deliveries",
    src: "https://storage.googleapis.com/public_storage_truq/website/siju_steps_4_wirubsjfs.jpg",
    alt: 'range-of-vehicle-sizes'
  },
  {
    id: 5,
    title: "Collect payment upon delivery",
    src: "https://storage.googleapis.com/public_storage_truq/website/siju_steps_5_bfgsdjfka.jpg",
    alt: 'payment-on-delivery'
  },
];

export const SijuTestimonies = [
  {
    content: "I used to wake up very early by manually matching drivers to trips, but with Siju, the whole process is automated and easy!",
    image: null,
    author: "Kingsley Chukwu - 3PL Manager, Trade Depot",
    medium: "",
    imageBg: "bg-info"
  },
  {
    content: "Siju makes our logistics optimization process very easy, all we have to do is upload our manifest and boom! Aggregated and distributed evenly across board.",
    image: null,
    author: "Kunle",
    medium: "",
    imageBg: "bg-success"
  },
  {
    content: "Its Amazing that truQ could pull this off! Super impressed, no other solution for the market like this. Well Done guys.",
    image: null,
    author: "Cynthia Okeke",
    medium: "",
    imageBg: "bg-secondary"
  }
]

export const Slides = [
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/career_truqstars_1.jpg",
    alt: "Slide 1",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/career_truQstars_2_trusodf.jpg",
    alt: "Slide 2",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/career_truQstars_3_aqtuisod.jpg",
    alt: "Slide 3",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/career_truQstars_4_nbdcshjsd.jpg",
    alt: "Slide 4",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/career_truQstars_5_mvidgsdl.jpg",
    alt: "Slide 5",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/career_truqstars_6_lmcbhds.jpg",
    alt: "Slide 6",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/career_truqstars_7_zatuiosh.jpg",
    alt: "Slide 7",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/career_truqstars_8_cpandks.jpg",
    alt: "Slide 8",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/career_truqstars_9_vxsjfhis.jpg",
    alt: "Slide 9",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/career_we_work_together_2_sfakkhr.jpg",
    alt: "Slide 10",
  },
]

export const Solutions = [
  {
    imgAlt: "truQDriverApp",
    imgSrc: solution1,
    name: "Drivehub by truQ",
    imgOrder: ColumnOrder.SECOND,
    spacing: "",
    details: [
      {
        subHeading: "i. Financing:",
        description: "Get your operational expenses sorted without the pressure of one off payment, everything from repairs, to spare parts, and insurance"
      },
      {
        subHeading: "ii. Microloans:",
        description: "Access quick loans for emergency needs and expenses"
      },
      {
        subHeading: "iii. Partial payment collection:",
        description: "Automate how you receive payments in full-pre trip, or in parts at pickup and drop off"
      },
      {
        subHeading: "iv. Fuel Card:",
        description: "Fuel fuel access fuel station with your special truQ card to enjoy fuel financing and discounts."
      },
      {
        subHeading: "v. Discounts:",
        description: "Get your operational needs serviced by our integrated partners to enjoy discounts and cashback on every transaction"
      },
      {
        subHeading: "vi. Early wage access:",
        description: "Get access to your earnings earlier than the payment cycle of any business you service"
      }
    ]
  },
  {
    imgAlt: "moveBytruQ",
    imgSrc: solution2,
    name: "Move by truQ",
    imgOrder: ColumnOrder.FIRST,
    spacing: "ms-md-5",
    details: [
      {
        subHeading: "i. Partial payment:",
        description: "Pay for your trip on your terms, in full-pre trip, or in parts at pickup and drop off"
      },
      {
        subHeading: "ii. Seamless Payment:",
        description: "Pay for your trip, additional moving services and domestic artisans without hassle"
      },
      {
        subHeading: "iii. truQ Points:",
        description: "Spend your truQ oints to access domestic artisans or on your next move"
      }
    ]
  },
  {
    imgAlt: "siju",
    imgSrc: solution3,
    name: "Siju by truQ",
    imgOrder: ColumnOrder.SECOND,
    spacing: "",
    details: [
      {
        subHeading: "i. Trip financing:",
        description: "Get your business deliveries done without the immediate cashflow and expense burden"
      },
      {
        subHeading: "ii. Wallet service:",
        description: "Pay for your trip, additional moving services and domestic artisans without hassle"
      },
      {
        subHeading: "iii. Wallet service:",
        description: "Leverage our wallet service to pay for your deliveries without the complication of bank transfers"
      }
    ]
  },
];

export const TripSteps = [
  {
    option: "Book your move"
  },
  {
    option: "Connect instantly with the nearest driver"
  },
  {
    option: "Driver arrives promptly as scheduled"
  },
  {
    option: "Driver gets paid upon delivery completion"
  },
  {
    option: "Receive real time updates on your move"
  },
];

export const TruQAppTestimonies = [
  {
    content: "Working as a business driver via truQ opened doors. The wallet automation simplifies payment.",
    image: null,
    author: "Femi Olakunle",
    medium: "",
    imageBg: "bg-info"
  },
  {
    content: "truQ just makes it easy to make more money. Even with my korope (Small van), I still make enough.",
    image: null,
    author: "David Kizito",
    medium: "",
    imageBg: "bg-success"
  },
  {
    content: "The New Points system just makes sense, extra money for completing more trips even after getting paid.",
    image: null,
    author: "Uche Okeke",
    medium: "",
    imageBg: "bg-secondary"
  }
];

import TruQStars from "../../../../components/fragment/TruQStars";
import YellowArchSVG from "../../../../assets/images/svgs/yellow-arch.svg";
import {Image} from "react-bootstrap";

const BestPlaceToWork = () => (
  <section>
    <div className="ms-lg-90 mt-xxl-180 ps-xxl-400 my-5 mt-md-100">
      <h2>What makes us — us</h2>
      <Image alt="best_place_to_work" src={YellowArchSVG} className="ms-5"/>
    </div>
    <TruQStars/>
  </section>
)
export default BestPlaceToWork

import {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import PrimaryButton from "./PrimaryButton";
import {MenuType, SubMenuType} from "../../modules/enums";


const GetStartedButton = ({className, segment}: {className?: string; segment?: string}) => {
  const location = useLocation(), navigate = useNavigate();
  const [lastSegment, setLastSegment] = useState('');

  useEffect(() => getPath(), [location]);

  const getPath = () => {
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    setLastSegment(lastSegment);
  };

  const getState = (key: string) => {
    switch (key) {
      case SubMenuType.TRUQ_APP:
        return "TRUQ";
      case SubMenuType.SIJU:
        return "SIJU";
      case SubMenuType.TRUQ:
        return "MOVE";
      case "PARTNER":
        return "PARTNER";
      default:
        return null;
    }
  };

  return (
    <PrimaryButton
      btn={{
        text: 'Get Started',
        btnClassName: `btn-block ${className ?? "px-4"}`,
        clickFn: () => navigate(`/${MenuType.GET_STARTED}`, {state: getState(segment === 'PARTNER' ? segment : lastSegment)})
      }}
    />
  )
}

export default GetStartedButton;

import { Container, Image } from "react-bootstrap";
import GetStartedButton from "../../../../components/buttons/GetStartedButton";

const SijuHero = ({ handleClick }: { handleClick: () => void }) => (
  <section className="siju-hero">
    <Container className="d-flex flex-column flex-wrap">
      <div className="text-center w-720 align-self-center">
        <h2 className="text-indigo">Powering <span className="text-orange px-1">Businesses</span> into New Revenue Milestones</h2>
        <p className="hero-description mb-4">
          Explore a game-changing logistics operations on Siju Designed to help your business win.
        </p>
        <GetStartedButton />
      </div>


      <div className="hero_container d-flex justify-content-center align-self-center my-5">
        <Image
          alt="HERO"
          src="https://storage.googleapis.com/public_storage_truq/website/siju_hero_oisgfjsf.jpg"
          className="img-fluid object-fit-cover curved-images"
        />
      </div>
    </Container>
  </section>
)

export default SijuHero

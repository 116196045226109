import {Col, Image, Row} from "react-bootstrap";

const WelcomedHere = () => (
  <Row className="mt-5 mt-xxl-180">
    <Col lg={5} className="mt-md-70 mt-5 text-start">
      <div className="my-0 my-lg-5 mt-md-70">
        <h4>Everyone is Welcomed Here !</h4>
        <p className="font-size-18 text-lg-start my-2">
          Segregation and discrimination are not welcomed here, in
          fact we build our strength around our differences
        </p>
      </div>
      <div className="mt-md-70 my-4 my-md-5">
        <h4>truQsters Are Lovers!</h4>
        <p className="font-size-18 text-lg-start my-2">
          We love what we do, who we do it with and who we do it for
        </p>
      </div>
    </Col>
    <Col lg={7} className="mt-md-70">
      <Image
        alt="CULTURE"
        className="w-100 mt-md-70 curved-images"
        src="https://storage.googleapis.com/public_storage_truq/website/about_us_people_5_sdhdgf.jpeg"
      />
      <div className="mt-4 mt-lg-130 text-start">
        <h4>We Are Different </h4>
        <p className="font-size-18 text-lg-start mt-2 mt-md-2">
          truQstars are non-conformists; we don't go with the flow, we set
          the bar. We do things differently and better
        </p>
      </div>
    </Col>
  </Row>
)

export default WelcomedHere

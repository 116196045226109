import {Logos} from "../../../utils/mapper/Mapper";
import {Image} from "react-bootstrap";

const NewsFeatures = () => {
  const firstRowLogos = Logos.slice(0, 6);
  const secondRowLogos = Logos.slice(6, 11);

  return (
    <section className="text-center mt-5 mt-md-70 mt-xxl-180">
      <h5 className="mb-3">Partners</h5>
      <div className="py-2 w-100 mx-auto">
        <div className="custom-mobile-layout w-850 mx-auto">
          {firstRowLogos.map((logo) => (
            <div className="logo-item mb-5"><Image src={logo.src} alt={logo.alt} height={40}/></div>
          ))}
        </div>

        <div className="custom-mobile-layout w-1000 mx-auto justify-content-lg-between justify-content-center px-md-3">
          {secondRowLogos.map((logo) => (
            <div className="logo-item mb-5">
              <Image src={logo.src} alt={logo.alt} height={50}/>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default NewsFeatures;

import {Image} from "react-bootstrap";

const AboutUSHero = () => (
  <section className="grid-white mt-5 px-md-4 px-2">
    <h2 className="text-center align-self-center pt-4 pt-md-0">
      We are a one-stop shop for commercial fleet operators and businesses, providing solutions that make their
      third-party logistics interactions smarter, faster and more efficient
    </h2>
    <Image
      alt="HERO"
      src="https://storage.googleapis.com/public_storage_truq/website/aboutus_hero_dhsdub.jpeg"
      className="mx-auto my-5 rounded-4"
    />
  </section>
)
export default AboutUSHero;

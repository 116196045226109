import {Col, Image, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {MenuType, SubMenuType} from "../../../modules/enums";
import Shake from "../../../assets/images/pngs/handshake.png";
import PrimaryButton from "../../../components/buttons/PrimaryButton";

const TruqStars = () => {
  const navigate = useNavigate();

  return (
    <section className="text-center my-5 mt-md-130 mt-xxl-180">
      <h2 className="mb-2">
        Our People <Image src={Shake} alt="handshake" width={50} height={50} className="ms-2"/>
      </h2>
      <p className="text-center w-720 mx-auto mb-4 px-2">truQstars are the secret sauce behind the magic we cook at
        truQ, different people, expertise and background but are aligned in vision for what’s possible for Africa
        from our kitchen at truQ</p>
      <PrimaryButton
        btn={{
          text: "Discover Open Position",
          btnClassName: 'mx-auto mb-5',
          clickFn: () => navigate(`/${MenuType.COMPANY}/${SubMenuType.CAREER}`),
        }}
      />
      <Row className="py-2 w-100 mx-auto">
        <Col md={6} className="mb-3">
          <div className="people-1"/>
        </Col>
        <Col md={6} className="mb-3">
          <div className="people-2"/>
        </Col>
        <Col md={4} className="mb-3">
          <div className="people-3"/>
        </Col>
        <Col md={3} className="mb-3">
          <div className="people-4"/>
        </Col>
        <Col md={5} className="mb-3">
          <div className="people-5"/>
        </Col>
        {/* <Col xs={6} className="mb-3 px-1">
          <div className="people-1"/>
        </Col>
        <Col xs={6} className="mb-3 px-1">
          <div className="people-2"/>
        </Col>
        <Col xs={4} className="mb-3 px-1">
          <div className="people-3"/>
        </Col>
        <Col xs={4} md={3} className="mb-3 px-1 px-md-2">
          <div className="people-4"/>
        </Col>
        <Col xs={4} md={5} className="mb-3 px-1">
          <div className="people-5"/>
        </Col> */}
      </Row>
    </section>
  )
}

export default TruqStars;

import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Container} from "react-bootstrap";

import DriveHubHero from "./atom/hero/DriveHubhero";
import {AppTheme, MenuType} from "../../modules/enums";
import {useTheme} from "../../components/hooks/useTheme";
import DriveHubGetStarted from "./atom/DriveHubGetStarted";
import {TruQAppTestimonies} from "../../utils/mapper/Mapper";
import PageLayout from "../../components/layouts/PageLayout";
import Testimonies from "../../components/fragment/Testimony";
import AppStoreBanner from "../../components/fragment/AppStoreBanner";
import DriveHubEmpowerment from "./atom/DriveHubEmpowerment";


const DriveHub = () => {
  const appTheme = useTheme(), navigate = useNavigate();

  useEffect(() => {
    if (appTheme.theme === AppTheme.DARK) appTheme.toggleTheme(AppTheme.LIGHT);
  }, [appTheme]);

  const handleClick = () => navigate(`/${MenuType.CONSTRUCTION}`);

  return (
    <PageLayout>
      <DriveHubHero handleClick={handleClick}/>
      <Testimonies title={`Here’s what our users are saying`}
                   description={`We are not just solving a major problem, we are solving a major problem with a solution that truly works`}
                   testimonyData={TruQAppTestimonies}/>
      <Container>
        <DriveHubEmpowerment/>
        <DriveHubGetStarted handleClick={handleClick}/>
      </Container>
      <AppStoreBanner/>
    </PageLayout>
  );
}

export default DriveHub;
import {useState} from 'react';
import Slider from 'react-slick';
import {Slides} from '../../utils/mapper/Mapper';
import {Container, Image} from 'react-bootstrap';

const TruQStars = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: false,
    speed: 500,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    easing: "linear",
    focusOnSelect: true,
    pauseOnHover: true,
    centerMode: false,
    beforeChange: (_:any, next: any) => setCurrentIndex(next + 1),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          beforeChange: (_: any, next: number) => setCurrentIndex(next + 1),
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          beforeChange: (_: any, next: number) => setCurrentIndex(next + 1),
        }
      }
    ]
  };

  return (
    <section className="d-flex flex-column flex-lg-row gap-lg-5 px-3 px-md-0 mt-5">
      <div className="w-lg-45-p mt-md-100 mx-auto">
        <div className="w-600 w-100 text-center text-lg-start px-5 ms-xxl-300">
          <h4 className="line-spacing-05 text-start">Best Place To Work</h4>
          <p className="font-size-20 mt-4 text-start">
            We are building the most desirable place anyone would want
            to work; fun, result driven, fulfilling, welcoming and
            inclusive
            <br/>
          </p>
        </div>
      </div>
      <Container className="w-lg-55-p my-5 flaot-right mx-auto">
        <Slider {...settings}>
          {Slides.map((item, index) =>
            <Image src={item.src} alt={item.alt}
                   className={`img-fluid object-fit-fill h-410 px-2 rounded-5 ${currentIndex === index ? 'slide-active' : 'slide-inactive'}`}/>
          )}
        </Slider>
      </Container>
    </section>
  );
}

export default TruQStars;
